import { firestore } from 'firebase';
import { IsNotEmpty, IsInt, IsString } from 'class-validator';
import { SaImageGallery } from '.';

export interface SalExerciseCardItem {
  id: string;
  title: string;
  description: string;
  creatorId: string;
  video?: SaVideo;
  duration: string | null;
  groupSize: string | null;
  iconType?: string;
  sportType?: string;
  complexity?: SA_EXERCISE_COMPLEXITY;
  intensity?: string;
  tags: SA_EXERCISE_TAG[];
}
export interface SaExercise {
  id: string;
  title: string;
  created: firestore.Timestamp;
  creatorId: string;
  groupSizeMin: number;
  groupSizeMax: number;
  duration: number;
  tags?: SA_EXERCISE_TAG[];
  description: string;
  exerciseInfo?: SaExerciseInfo;
  videos?: SaVideo[];
  photos?: string[];
  sportType?: string;
  complexity?: SA_EXERCISE_COMPLEXITY;
  locale: string;
  status: SA_EXERCISE_STATUS;
  gallery?: SaImageGallery;
}

export enum SA_EXERCISE_STATUS {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum SA_EXERCISE_COMPLEXITY {
  LEVEL_OF_COMPLEXITY_1 = 'level_of_complexity_1',
  LEVEL_OF_COMPLEXITY_2 = 'level_of_complexity_2',
  LEVEL_OF_COMPLEXITY_3 = 'level_of_complexity_3',
}

export const SA_EXERCISE_COMPLEXITY_LEVELS = [
  SA_EXERCISE_COMPLEXITY.LEVEL_OF_COMPLEXITY_1,
  SA_EXERCISE_COMPLEXITY.LEVEL_OF_COMPLEXITY_2,
  SA_EXERCISE_COMPLEXITY.LEVEL_OF_COMPLEXITY_3,
];

export enum SA_EXERCISE_TAG {
  WARM_UP = 'WARM_UP',
  GAME_SESSION = 'GAME_SESSION',
  COOL_DOWN = 'COOL_DOWN',
}

export enum SA_EXERCISE_TAG_LABEL {
  WARM_UP = 'exercise_tag_warmup',
  GAME_SESSION = 'exercise_tag_gamesession',
  COOL_DOWN = 'exercise_tag_cooldown',
}

export interface SaExerciseInfo {
  description?: string;
  goals?: string;
  requirements?: string;
  variations?: string;
}

export interface SaVideo {
  videoUrl: string;
  videoText?: string;
  thumbnail?: string;
}

export class UpdateExerciseDTO {
  id: string;
  title?: string;
  groupSizeMin?: number;
  groupSizeMax?: number;
  duration?: number;
  description?: string;
  exerciseGoalIntro?: string;
  goals?: string;
  requirements?: string;
  variations?: string;
  videos?: SaVideo[];
  tags?: SA_EXERCISE_TAG[];
  status?: SA_EXERCISE_STATUS;
  sportType?: string;
  complexity?: SA_EXERCISE_COMPLEXITY;
  gallery?: SaImageGallery;
}

export class CancelEventDTO {
  @IsNotEmpty()
  eventId: string;
}

export class CreateExerciseDTO {
  @IsNotEmpty()
  title: string;

  @IsInt()
  groupSizeMin: number;

  @IsInt()
  groupSizeMax: number;

  @IsInt()
  duration: number;

  @IsString()
  description: string;

  sportType?: string;
  complexity?: string;

  exerciseGoalIntro?: string;
  goals?: string;
  requirements?: string;
  variations?: string;
  tags: SA_EXERCISE_TAG[];
  locale: string;
  status: string;
  videos: SaVideo[];
  gallery?: SaImageGallery;
}
