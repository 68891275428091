import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  MekPromptResult,
  MODAL_ACTION,
  SaModal,
} from 'shared/interfaces/modal.interface';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private modalSubject = new BehaviorSubject<SaModal>(null);
  private modalActionSubject = new BehaviorSubject<
    MODAL_ACTION | MekPromptResult
  >(null);

  constructor() {}

  public modal$() {
    return this.modalSubject.asObservable();
  }

  public modalAction$() {
    return this.modalActionSubject.asObservable();
  }

  public emitModal(modal: SaModal) {
    this.modalSubject.next(modal);
    this.modalActionSubject.next(MODAL_ACTION.OPEN);
    return this.modalAction$();
  }

  public closeModal() {
    this.modalActionSubject.next(MODAL_ACTION.CLOSE);
    this.modalActionSubject.next(null);
    this.modalSubject.next(null);
  }

  public cancelModal() {
    this.modalActionSubject.next(MODAL_ACTION.CANCEL);
    this.modalActionSubject.next(null);
    this.modalSubject.next(null);
  }

  public confirmModal() {
    this.modalActionSubject.next(MODAL_ACTION.CONFIRM);
    this.modalActionSubject.next(null);
    this.modalSubject.next(null);
  }

  public submitPrompt(inputVal: string) {
    this.modalActionSubject.next({ text: inputVal });
    this.modalActionSubject.next(null);
    this.modalSubject.next(null);
  }

  public denyModal() {
    this.modalActionSubject.next(MODAL_ACTION.DENY);
    this.modalActionSubject.next(null);
    this.modalSubject.next(null);
  }
}
