import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { User } from 'firebase';
import { filter, switchMap } from 'rxjs/operators';
import {
  CreateUserNotificationDTO,
  SaEvent,
  SaGroup,
  SaUserNotification,
  SA_COLLECTIONS,
  SA_USER_NOTIFICATION_TYPE,
} from 'shared/interfaces';
import { StoreService } from '../store/store.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(
    private auth: AngularFireAuth,
    private db: AngularFirestore,
    private store: StoreService,
    private functions: AngularFireFunctions
  ) {}

  public fetchMyNotifications(locale: string) {
    this.auth.user
      .pipe(
        filter(Boolean),
        switchMap((authUser: User) =>
          this.db
            .collection(SA_COLLECTIONS.NOTIFICATIONS)
            .doc(authUser.uid)
            .collection<SaUserNotification>(SA_COLLECTIONS.USER_NOTIFICATIONS)
            .valueChanges()
        )
      )
      .subscribe(notifications => {
        const localeNotifications = notifications.filter(notification => {
          return notification.locale === locale;
        });
        this.store.setUserNotifications(localeNotifications);
      });
  }

  public createUserNotification(data: CreateUserNotificationDTO) {
    this.functions
      .httpsCallable('createUserNotification')(data)
      .subscribe(
        val => {
          console.log(val);
        },
        error => {
          console.error(error);
        }
      );
  }

  public setNotificationAsSeen(id: string) {
    const userId = this.store.user$.getValue().id;
    if (userId) {
      this.functions
        .httpsCallable('setNotificationAsSeen')({
          notificationId: id,
          userId,
        })
        .subscribe(
          val => {
            console.log(val);
          },
          error => {
            console.error(error);
          }
        );
    }
  }

  public async generateNewEventUserNotifications(
    event: SaEvent,
    userIds: string[]
  ) {
    userIds.forEach(userId => {
      this.createUserNotification({
        userId,
        eventId: event.id,
        groupId: event.groupId,
        type: SA_USER_NOTIFICATION_TYPE.NEW_EVENT,
      });
    });
  }

  public async generateEventCancelledUserNotifications(
    eventId: string,
    userIds: string[]
  ) {
    userIds.forEach(userId => {
      this.createUserNotification({
        userId,
        eventId,
        type: SA_USER_NOTIFICATION_TYPE.EVENT_CANCELLED,
      });
    });
  }

  public async generatePendingGroupMembershipRequestUserNotification(
    group: SaGroup
  ) {
    this.createUserNotification({
      userId: group.ownerId,
      groupId: group.id,
      type: SA_USER_NOTIFICATION_TYPE.NEW_GROUP_MEMBERSHIP_REQUEST,
    });
  }

  public async generatePendingEventMembershipRequestUserNotification(
    event: SaEvent
  ) {
    this.createUserNotification({
      userId: event.ownerId,
      eventId: event.id,
      type: SA_USER_NOTIFICATION_TYPE.NEW_EVENT_MEMBERSHIP_REQUEST,
    });
  }

  public async generateGroupMembershipAcceptedRequestUserNotification(
    userId: string,
    group: SaGroup
  ) {
    this.createUserNotification({
      userId,
      groupId: group.id,
      type: SA_USER_NOTIFICATION_TYPE.GROUP_MEMBERSHIP_APPROVED,
    });
  }

  public async generateGroupMembershipDeniedRequestUserNotification(
    userId: string,
    group: SaGroup
  ) {
    this.createUserNotification({
      userId,
      groupId: group.id,
      type: SA_USER_NOTIFICATION_TYPE.GROUP_MEMBERSHIP_DENIED,
    });
  }

  public async generateEventMembershipAcceptedRequestUserNotification(
    userId: string,
    event: SaEvent
  ) {
    this.createUserNotification({
      userId,
      eventId: event.id,
      type: SA_USER_NOTIFICATION_TYPE.EVENT_SUBSCRIPTION_APPROVED,
    });
  }

  public async generateEventMembershipDeniedRequestUserNotification(
    userId: string,
    event: SaEvent
  ) {
    this.createUserNotification({
      userId,
      eventId: event.id,
      type: SA_USER_NOTIFICATION_TYPE.EVENT_SUBSCRIPTION_DENIED,
    });
  }
}
