import { IsString } from 'class-validator';

export interface SaEventParticipant {
  id: string;
  userId: string;
  eventId: string;
  role: SA_EVENT_PARTICIPANT_ROLE;
  status: SA_EVENT_PARTICIPANT_STATUS;
  timestamp: any;
}

export enum SA_EVENT_PARTICIPANT_ROLE {
  ADMIN = 'admin',
  PARTICIPANT = 'participant',
  TRAINER = 'trainer',
  CO_TRAINER = 'co_trainer',
}

export enum SA_EVENT_PARTICIPANT_STATUS {
  WAITING_FOR_ACCEPT = 'waiting_for_accept',
  ACCEPTED = 'accepted',
}

export class EventParticipantDTO {
  eventId?: string;

  @IsString()
  userId: string;

  status?: SA_EVENT_PARTICIPANT_STATUS;
  role?: SA_EVENT_PARTICIPANT_ROLE;
}

export class DeleteEventDTO {
  eventId: string;
  ownerId: string;
}

export class RemoveEventParticipantDTO {
  eventId: string;
  userId: string;
}

export class AddEventParticipantDTO {
  eventId: string;
  userId: string;
  status: SA_EVENT_PARTICIPANT_STATUS;
  role: SA_EVENT_PARTICIPANT_ROLE;
}

export class UpdateEventParticipantStatusDTO {
  eventId: string;
  userId: string;
  status: SA_EVENT_PARTICIPANT_STATUS;
}
