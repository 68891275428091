import { firestore } from 'firebase';
import { IsNotEmpty } from 'class-validator';

export interface SaForumTopic {
  id: string;
  title: string;
  locale: string;
  creatorId: string;
  created: firestore.Timestamp;
}

export class UpdateForumTopicDTO {
  id: string;
  title?: string;
}

export class CreateForumTopicDTO {
  @IsNotEmpty()
  title: string;

  @IsNotEmpty()
  locale: string;
}

export class DeleteForumTopicDTO {
  id: string;
}
