import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { User } from 'firebase';
import { BehaviorSubject } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import {
  CreateUserDTO,
  FIREBASE_LANG_KEY,
  ResetPasswordDTO,
  SaNotificationSetting,
  SaUser,
  SaUserFeature,
  SaUserNotificationSetting,
  SA_COLLECTIONS,
  SA_NAVIGATION_PATH,
  SA_QUERY_PARAMS,
  SA_USER_ROLES,
  TOAST_TYPE,
  UpdateUserDTO,
} from 'shared/interfaces';
import { ToastService } from 'shared/modules/toast-controller/services/toast.service';
import { StoreService } from '../store/store.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private updateUserLoading = new BehaviorSubject<string>(null);
  private createUserLoading = new BehaviorSubject<string>(null);

  public notificationToken: string;
  public updateNotificationCount = 0;

  constructor(
    private auth: AngularFireAuth,
    private store: StoreService,
    private db: AngularFirestore,
    private functions: AngularFireFunctions,
    private toastService: ToastService,
    private router: Router,
    private analytics: AngularFireAnalytics
  ) {}

  public init() {}

  public updateUserLoading$() {
    return this.updateUserLoading;
  }

  public createUserLoading$() {
    return this.createUserLoading;
  }

  public login(email: string, pwd: string) {
    this.analytics.logEvent('login');
    return this.auth.signInWithEmailAndPassword(email, pwd);
  }

  public async logout() {
    const user = this.store.user$.value;
    if (user && Capacitor.isNativePlatform()) {
      console.log('logout updateUserNotificationToken');
      this.updateUserNotificationToken(user.id, null);
    }

    this.store.setUser(null);
    await this.router.navigate([
      this.store.locale,
      SA_NAVIGATION_PATH.LOGIN,
      SA_NAVIGATION_PATH.LOGOUT,
    ]);
    await this.auth.signOut();
    await this.router.navigate([this.store.locale]);
    window.location.reload();
  }

  public register(userDto: CreateUserDTO) {
    this.createUser(userDto);
  }

  public fetchUser() {
    this.auth.user
      .pipe(
        filter(Boolean),
        switchMap((authUser: User) =>
          this.db
            .collection(SA_COLLECTIONS.USERS)
            .doc<SaUser>(authUser.uid)
            .valueChanges()
        )
      )
      .subscribe(user => {
        this.store.setUser(user);

        if (
          this.notificationToken &&
          this.notificationToken !== user.notificationToken
        ) {
          this.updateUserNotificationToken(user.id, this.notificationToken);
          console.log('fetchUser updateUserNotificationToken');
        }
      });
  }

  public fetchUserById(id: string) {
    return this.db
      .collection(SA_COLLECTIONS.USERS)
      .doc<SaUser>(id)
      .valueChanges();
  }

  public async trainerExists(userId: string): Promise<boolean> {
    let trainerExists = false;

    const usersRef = await this.db
      .collection(SA_COLLECTIONS.USERS)
      .ref.where(SA_QUERY_PARAMS.ID, '==', userId)
      .where(SA_QUERY_PARAMS.ROLES, 'array-contains', SA_USER_ROLES.TRAINER);

    await usersRef.get().then(querySnapshot => {
      querySnapshot.docs.forEach(doc => {
        trainerExists = doc.exists;
      });
    });
    return trainerExists;
  }

  public async getTrainerIdForEmail(email: string): Promise<string> {
    let trainerId: string;

    const usersRef = await this.db
      .collection(SA_COLLECTIONS.USERS)
      .ref.where(SA_QUERY_PARAMS.EMAIL, '==', email)
      .where(SA_QUERY_PARAMS.ROLES, 'array-contains', SA_USER_ROLES.TRAINER);

    await usersRef.get().then(querySnapshot => {
      querySnapshot.docs.forEach(doc => {
        trainerId = doc.id;
      });
    });
    return trainerId;
  }

  public async getUserIdForEmail(email: string): Promise<string> {
    let userId: string;

    const usersRef = await this.db
      .collection(SA_COLLECTIONS.USERS)
      .ref.where(SA_QUERY_PARAMS.EMAIL, '==', email);

    await usersRef.get().then(querySnapshot => {
      querySnapshot.docs.forEach(doc => {
        userId = doc.id;
      });
    });
    return userId;
  }

  public fetchUserFeatures() {
    this.auth.user
      .pipe(
        filter(Boolean),
        switchMap((authUser: User) =>
          this.db
            .collection(SA_COLLECTIONS.USERS)
            .doc(authUser.uid)
            .collection<SaUserFeature>(SA_COLLECTIONS.USER_FEATURES)
            .valueChanges()
        )
      )
      .subscribe(features => {
        this.store.setUserFeatures(features);
      });
  }

  public fetchUserNotificationSettings() {
    this.auth.user
      .pipe(
        filter(Boolean),
        switchMap((authUser: User) =>
          this.db
            .collection(SA_COLLECTIONS.USERS)
            .doc(authUser.uid)
            .collection<SaUserNotificationSetting>(
              SA_COLLECTIONS.USER_NOTIFICATION_SETTINGS
            )
            .valueChanges()
        )
      )
      .subscribe(settings => {
        if (settings) {
          this.store.setUserNotificationSettings(settings);
        } else {
          this.store.setUserNotificationSettings([]);
        }
      });
  }

  public updateNotificationSettings(
    userId: string,
    settings: SaNotificationSetting[]
  ) {
    return this.functions.httpsCallable('updateUserNotificationSettings')({
      settings,
      userId,
    });
  }

  public createUser(userDto: CreateUserDTO) {
    this.createUserLoading.next(userDto.email);
    this.functions
      .httpsCallable('createUser')(userDto)
      .subscribe(
        user => {
          if (user) {
            this.login(userDto.email, userDto.password);
            this.toastService.emitToast({
              type: TOAST_TYPE.SUCCESS,
              text: 'successfully registered new user',
            });
            this.analytics.logEvent('sign_up');
            this.createUserLoading.next(null);
          }
        },
        error => {
          console.error(error);
        }
      );
  }

  public getResetPasswordLink$() {
    const authUser = this.store.user$.getValue();
    const resetPasswordDTO: ResetPasswordDTO = {
      id: authUser.id,
      email: authUser.email,
      locale: this.store.locale,
      lang: FIREBASE_LANG_KEY[this.store.locale],
    };
    return this.functions.httpsCallable('resetPassword')(resetPasswordDTO);
  }

  public updateUser(userDto: UpdateUserDTO) {
    this.updateUserLoading.next(userDto.id);
    this.functions
      .httpsCallable('updateUser')(userDto)
      .subscribe(
        (user: SaUser) => {
          this.toastService.emitToast({
            type: TOAST_TYPE.SUCCESS,
            text: 'toast_successfully_updated_user',
          });
          this.updateUserLoading.next(null);
        },
        error => {
          console.error(error);
          this.toastService.emitToast({
            type: TOAST_TYPE.ERROR,
            text: 'taost_something_went_wrong',
          });
        }
      );
  }

  public deleteUser(uid: string): void {
    this.functions
      .httpsCallable('deleteUser')(uid)
      .subscribe(
        () => {
          window.location.reload();
        },
        error => {
          console.error(error);
          this.toastService.emitToast({
            type: TOAST_TYPE.ERROR,
            text: 'taost_something_went_wrong',
          });
        }
      );
  }

  public updateUserNotificationToken(
    id: string,
    notificationToken: string
  ): void {
    console.log(
      'updateUserNotificationToken updateNotificationCount',
      this.updateNotificationCount
    );
    if (
      this.updateNotificationCount <= 1 &&
      notificationToken !== this.store.user$?.value?.notificationToken
    ) {
      this.updateNotificationCount += 1;
      this.functions
        .httpsCallable('updateUserNotificationToken')({ id, notificationToken })
        .subscribe(result => {
          this.notificationToken = null;
          console.log('updateUserNotificationToken()', result);
        });
    }
  }
}
