import { firestore } from 'firebase';
import { SaVideo } from './exercise.interface';
import { IsString, IsInt, IsNotEmpty } from 'class-validator';
import { SaImageGallery } from '.';

export interface SalTrainingCardItem {
  id: string;
  title: string;
  description: string;
  creatorId: string;
  video: SaVideo;
  duration: string;
  groupSize: string;
  iconType: string;
  sportType?: string;
  complexity?: string;
  intensity?: string;
}
export interface SaTraining {
  id: string;
  title: string;
  created: firestore.Timestamp;
  creatorId: string;
  groupSizeMin: number;
  groupSizeMax: number;
  duration: number;
  description: string;
  trainingInfo?: SaTrainingInfo;
  videos?: SaVideo[];
  photos?: string[];
  iconType: string;
  intensity?: SA_TRAINING_INTENSITY;
  locale: string;
  status: SA_TRAINING_STATUS;
  gallery?: SaImageGallery;
}

export enum SA_TRAINING_INTENSITY {
  LEVEL_OF_INTENSITY_1 = 'level_of_intensity_1',
  LEVEL_OF_INTENSITY_2 = 'level_of_intensity_2',
  LEVEL_OF_INTENSITY_3 = 'level_of_intensity_3',
}

export const SA_TRAINING_INTENSITY_LEVELS = [
  SA_TRAINING_INTENSITY.LEVEL_OF_INTENSITY_1,
  SA_TRAINING_INTENSITY.LEVEL_OF_INTENSITY_2,
  SA_TRAINING_INTENSITY.LEVEL_OF_INTENSITY_3,
];

export enum SA_TRAINING_STATUS {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export interface SaTrainingInfo {
  description?: string;
  warmUp?: string;
  gameSession?: string;
  coolDown?: string;
}

export class UpdateTrainingDTO {
  id: string;
  title?: string;
  groupSizeMin?: number;
  groupSizeMax?: number;
  duration?: number;
  description?: string;
  iconType?: string;
  trainingInfoIntro?: string;
  warmUp?: string;
  gameSession?: string;
  coolDown?: string;
  videos?: SaVideo[];
  status?: SA_TRAINING_STATUS;
  intensity?: SA_TRAINING_INTENSITY;
  gallery?: SaImageGallery;
}

export class CreateTrainingDTO {
  @IsNotEmpty()
  title: string;

  @IsInt()
  groupSizeMin: number;

  @IsInt()
  groupSizeMax: number;

  @IsInt()
  duration: number;

  @IsString()
  description: string;
  iconType: string;
  intensity?: string;

  trainingInfoIntro?: string;
  warmUp?: string;
  gameSession?: string;
  coolDown?: string;
  locale: string;
  status: string;
  videos?: SaVideo[];
  gallery?: SaImageGallery;
}
