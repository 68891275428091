import { IsNotEmpty } from 'class-validator/decorator/decorators';
import { firestore } from 'firebase';
import { SA_ENTITY_TYPE } from './entity.interface';
import { SA_LOCALES } from './environment.interface';
import { SaEvent } from './event.interface';
import { SaGroup } from './group.interface';

export interface SaUserNotification {
  id: string;
  userId: string;
  createdAt: firestore.Timestamp;
  opened: boolean;
  body: SaUserNotificationBody;
  type: SA_USER_NOTIFICATION_TYPE;
  locale: string;
}

export interface SaUserNotificationBody {
  date: firestore.Timestamp;
  entityType?: SA_ENTITY_TYPE;
  entityId?: string;
  type?: SA_USER_NOTIFICATION_TYPE;
  eventId?: string;
  groupId?: string;
}

export interface SaNotificationFlapItem {
  id: string;
  type: SA_USER_NOTIFICATION_TYPE;
  imageUrl: string;
  imageAlt: string;
  event?: SaEvent;
  eventId?: string;
  group?: SaGroup;
  groupId?: string;
}

export enum SA_USER_NOTIFICATION_TYPE {
  NEW_EVENT = 'NEW_EVENT',
  NEW_MESSAGE = 'NEW_MESSAGE',
  GROUP_MEMBERSHIP_APPROVED = 'GROUP_MEMBERSHIP_APPROVED',
  GROUP_MEMBERSHIP_DENIED = 'GROUP_MEMBERSHIP_DENIED',
  EVENT_SUBSCRIPTION_APPROVED = 'EVENT_SUBSCRIPTION_APPROVED',
  EVENT_SUBSCRIPTION_DENIED = 'EVENT_SUBSCRIPTION_DENIED',
  EVENT_CANCELLED = 'EVENT_CANCELLED',
  NEW_GROUP_MEMBERSHIP_REQUEST = 'NEW_GROUP_MEMBERSHIP_REQUEST',
  NEW_EVENT_MEMBERSHIP_REQUEST = 'NEW_EVENT_MEMBERSHIP_REQUEST',
  UNSUBSCRIPTION_FROM_EVENT = 'UNSUBSCRIPTION_FROM_EVENT',
  UNSUBSCRIPTION_FROM_GROUP = 'UNSUBSCRIPTION_FROM_GROUP',
}

export interface SaEventCreatedNotification {
  groupId: string;
  eventId: string;
}

export interface CreateNewEventUserNotificationBody {
  eventId: string;
  date: firestore.Timestamp;
  groupId: string;
}

export interface EventCancelledUserNotificationBody {
  eventId: string;
  date: firestore.Timestamp;
}
export interface MembershipUpdatedUserNotificationBody {
  date: firestore.Timestamp;
  entityType: SA_ENTITY_TYPE;
  entityId: string;
}

export class CreateUserNotificationDTO {
  userId: string;
  type: SA_USER_NOTIFICATION_TYPE;
  eventId?: string;
  groupId?: string;
}

export class CreatePushNotificationDTO {
  notificationToken: string;
  type: SA_USER_NOTIFICATION_TYPE;
  locale?: SA_LOCALES;
  eventId?: string;
  groupId?: string;
}

export class UserPushNotificationDTO {
  @IsNotEmpty()
  id: string;
  notificationToken?: string;
}
