import { SaEnvironment, SA_LOCALES } from 'shared/interfaces';

export const environment: SaEnvironment = {
  production: true,
  locales: [SA_LOCALES.EN, SA_LOCALES.NL, SA_LOCALES.DE_AT, SA_LOCALES.DE_CH],
  admins: {
    en: 'BFavmfcekPcwDe4Q3kLViO3JZTr1',
    nl: 'MCtfmNAWK3ZrFJgBdZnm7T7Y0Kh2',
    'de-at': 'EAcaUkEPimhsGLbnLzslaA7C8Xx2',
    'de-ch': 'MiSDNKmSUsOfPculdmUhnTDzsT83',
  },
  defaultLocale: null,
  showNewsletterCheckboxLocales: [SA_LOCALES.EN, SA_LOCALES.DE_CH],
  hyvorURL: 'https://salsa-fun-develop.web.app',
  firebase: {
    apiKey: 'AIzaSyB91HOBDiYKwE3VGDucQPCBOvVik_lAQtI',
    authDomain: 'salsa-fun-develop.firebaseapp.com',
    databaseURL: 'https://salsa-fun-develop.firebaseio.com',
    projectId: 'salsa-fun-develop',
    storageBucket: 'salsa-fun-develop.appspot.com',
    messagingSenderId: '317778536989',
    appId: '1:317778536989:web:99233b5319dec79b1b34f4',
    measurementId: 'G-CT057WKB9X',
  },
};
