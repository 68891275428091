import { Injectable } from '@angular/core';
import { firestore } from 'firebase';
import { environment } from 'projects/salsa-fun/src/environments/environment';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  SaDownload,
  SaEvent,
  SaEventParticipant,
  SaExercise,
  SaExerciseUserFavorite,
  SaFaq,
  SaFeature,
  SaForumTopic,
  SaGroup,
  SaGroupMember,
  SaHomeContent,
  SaHomeLocation,
  SaQuote,
  SaUser,
  SaUserFeature,
  SaUserNotification,
  SaUserNotificationSetting,
  SA_EVENT_PARTICIPANT_ROLE,
  SA_GROUP_MEMBER_ROLE,
  SA_LOCALES,
  SA_USER_FEATURE,
  SA_USER_NOTIFICATION_TYPE,
  SA_USER_ROLES,
} from 'shared/interfaces';

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  private _activeGroups = new BehaviorSubject<SaGroup[]>([]);
  private _groups = new BehaviorSubject<SaGroup[]>([]);
  private _groupMembers = new BehaviorSubject<SaGroupMember[]>([]);
  private _pendingGroupMemberships = new BehaviorSubject<SaGroupMember[]>([]);
  private _pendingEventMemberships = new BehaviorSubject<SaEventParticipant[]>(
    []
  );
  private _user = new BehaviorSubject<SaUser>(null);
  private _admin = new BehaviorSubject<SaUser>(null);
  private _userFeatures = new BehaviorSubject<SaUserFeature[]>(null);
  private _userNotificationSettings = new BehaviorSubject<
    SaUserNotificationSetting[]
  >(null);
  private _serialParentEvents = new BehaviorSubject<SaEvent[]>([]);
  private _upcomingEvents = new BehaviorSubject<SaEvent[]>([]);
  private _pastEvents = new BehaviorSubject<SaEvent[]>([]);
  private _upcomingIntervisionEvents = new BehaviorSubject<SaEvent[] | null>(
    null
  );
  private _forumTopics = new BehaviorSubject<SaForumTopic[] | null>(null);
  private _myEvents = new BehaviorSubject<SaEvent[]>([]);
  private _myIntervisionEvents = new BehaviorSubject<SaEvent[]>([]);
  private _eventParticipants = new BehaviorSubject<SaEventParticipant[]>([]);
  private _intervisionEventParticipants = new BehaviorSubject<
    SaEventParticipant[]
  >([]);
  private _notifications = new BehaviorSubject<SaUserNotification[]>([]);
  private _userFavorites = new BehaviorSubject<SaExerciseUserFavorite[]>([]);
  private _userExerciseFavorites = new BehaviorSubject<SaExercise[]>([]);
  private _downloads = new BehaviorSubject<SaDownload[]>(null);
  private _faqs = new BehaviorSubject<SaFaq[]>(null);
  private _userFaqs = new BehaviorSubject<SaFaq[]>([]);
  private _trainerFaqs = new BehaviorSubject<SaFaq[]>([]);
  private _gdprStatement = new BehaviorSubject<string>(null);
  private _homeContent = new BehaviorSubject<SaHomeContent>({
    features: [],
    quotes: [],
    hero: '',
  });

  private _homeLocations = new BehaviorSubject<SaHomeLocation[]>(null);

  public locale: SA_LOCALES = environment.defaultLocale;
  public adminId: string = undefined;
  public loggedIn = false;
  public isTrainer = false;
  public isAdmin = false;

  private _groupInputSearch = new BehaviorSubject<string>(null);
  private _eventInputSearch = new BehaviorSubject<string>(null);

  constructor() {}

  public setLoggedIn(loggedIn: boolean) {
    this.loggedIn = loggedIn;
  }

  public setIsTrainer(isTrainer: boolean) {
    this.isTrainer = isTrainer;
  }

  public setIsAdmin(isAdmin: boolean) {
    this.isAdmin = isAdmin;
  }

  /**
   *
   * LOCALE
   *
   */

  public setLocale(locale: SA_LOCALES) {
    if (environment.locales.indexOf(locale) > -1) {
      this.locale = locale;
      this.adminId = environment.admins[locale];
    }
  }

  /**
   *
   * NOTIFICATIONS
   *
   */

  get notifications$() {
    return this._notifications;
  }

  public setUserNotifications(notifications: SaUserNotification[]) {
    const relevantNtifications = notifications.filter(notification => {
      const isDisabled = this.hasNotificationDisabled(notification.type);
      return !isDisabled;
    });
    this._notifications.next(relevantNtifications);
  }

  /**
   *
   * AUTHENTICATED USER
   *
   */

  public setUser(user: SaUser) {
    this._user.next(user);
    this.setLoggedIn(!!user);
    this.setIsTrainer(user?.roles?.indexOf(SA_USER_ROLES.TRAINER) > -1);
    this.setIsAdmin(user?.roles?.indexOf(SA_USER_ROLES.ADMIN) > -1);
  }

  get user$() {
    return this._user;
  }

  get userAsObservable(): Observable<SaUser | null> {
    return this._user.asObservable();
  }

  public setUserFeatures(features: SaUserFeature[]) {
    this._userFeatures.next(features);
  }

  get userFeatures$() {
    return this._userFeatures;
  }

  public hasFeature(feature: SA_USER_FEATURE) {
    const relevantFeature = this.userFeatures$
      .getValue()
      ?.filter(f => f.key === feature)[0];
    return relevantFeature?.value;
  }

  public setUserNotificationSettings(settings: SaUserNotificationSetting[]) {
    this._userNotificationSettings.next(settings);
  }

  get userNotificationSettings$() {
    return this._userNotificationSettings;
  }

  public hasNotificationDisabled(type: SA_USER_NOTIFICATION_TYPE) {
    const relevantFeature = this.userNotificationSettings$
      .getValue()
      ?.filter(f => f.key === type)[0];
    return relevantFeature?.value === false;
  }

  public setAdmin(user: SaUser) {
    this._admin.next(user);
  }
  get admin$() {
    return this._admin;
  }

  /**
   *
   * GROUPS
   *
   */

  public setActiveGroups(activeGroups: SaGroup[]) {
    this._activeGroups.next(activeGroups);
  }

  get activeGroups$() {
    return this._activeGroups;
  }

  public setGroups(groups: SaGroup[]) {
    this._groups.next(groups);
  }

  get groups$() {
    return this._groups;
  }

  public getGroupsAsAllTrainerRoles$(): Observable<SaGroup[]> {
    return combineLatest([
      this.getGroupMemberAsRoles$([
        SA_GROUP_MEMBER_ROLE.TRAINER,
        SA_GROUP_MEMBER_ROLE.CO_TRAINER,
      ]),
      this.groups$,
    ]).pipe(
      map(([members, groups]) => {
        return members
          .map(trainer => groups?.find(event => event?.id === trainer.groupId))
          .filter(Boolean);
      })
    );
  }

  public getGroupsAs$(role: SA_GROUP_MEMBER_ROLE): Observable<SaGroup[]> {
    return combineLatest([this.getGroupMemberAs$(role), this.groups$]).pipe(
      map(([members, groups]) => {
        return members
          .map(trainer => groups?.find(event => event?.id === trainer.groupId))
          .filter(Boolean);
      })
    );
  }

  get groupsAsTrainer$() {
    return combineLatest([this._groupMembers, this._groups]).pipe(
      map(([members, groups]) => {
        const memberAsTrainer = members?.filter(
          member => member.role === SA_GROUP_MEMBER_ROLE.TRAINER
        );

        return memberAsTrainer
          .map(trainer => groups?.find(event => event.id === trainer.groupId))
          .filter(Boolean);
      })
    );
  }

  private getGroupMemberAs$(role: SA_GROUP_MEMBER_ROLE) {
    return this._groupMembers.pipe(
      map(members => members.filter(member => member.role === role))
    );
  }

  private getGroupMemberAsRoles$(roles: SA_GROUP_MEMBER_ROLE[]) {
    return this._groupMembers.pipe(
      map(members =>
        members.filter(member => roles.indexOf(member.role) !== -1)
      )
    );
  }

  public setGroupMembers(groupMembers: SaGroupMember[]) {
    this._groupMembers.next(groupMembers);
  }

  get groupMembers$() {
    return this._groupMembers.asObservable();
  }

  public setPendingGroupMemberships(groupMembers: SaGroupMember[]) {
    this._pendingGroupMemberships.next(groupMembers);
  }

  get pendingGroupMemberships$() {
    return this._pendingGroupMemberships.asObservable();
  }

  public getPendingGroupMemberships(): BehaviorSubject<SaGroupMember[]> {
    return this._pendingGroupMemberships;
  }

  public setPendingEventMemberships(eventMembers: SaEventParticipant[]) {
    this._pendingEventMemberships.next(eventMembers);
  }

  get pendingEventMemberships$() {
    return this._pendingEventMemberships.asObservable();
  }

  public getPendingEventMemberships(): BehaviorSubject<SaEventParticipant[]> {
    return this._pendingEventMemberships;
  }

  /**
   *
   * INTERVISION EVENTS
   *
   */

  public setUpcomingIntervisionEvents(events: SaEvent[]) {
    this._upcomingIntervisionEvents.next(events);
  }

  get upcomingIntervisionEvents$() {
    return this._upcomingIntervisionEvents.asObservable();
  }

  public setMyIntervisionEvents(events: SaEvent[]) {
    this._myIntervisionEvents.next(events);
  }

  get myIntervisionEvents$() {
    return this._myIntervisionEvents.asObservable();
  }

  get myUpcomingIntervisionEvents$() {
    const now = firestore.Timestamp.fromDate(new Date());
    return this._myIntervisionEvents.pipe(
      map((events: SaEvent[]) =>
        events.filter(event => {
          return event?.start?.seconds > now.seconds;
        })
      )
    );
  }

  /**
   *
   * FORUM TOPICS
   *
   */

  public setForumTopics(forumTopics: SaForumTopic[]) {
    this._forumTopics.next(forumTopics);
  }

  get forumTopics$() {
    return this._forumTopics.asObservable();
  }

  /**
   *
   * EVENTS
   *
   */

  public setSerialParentEvents(events: SaEvent[]) {
    const combinedEvents = [
      ...this.getSerialParentEvents$.getValue(),
      ...events,
    ];
    const combinedEventsWithoutDuplicates = [
      ...new Map(combinedEvents.map(v => [v.id, v])).values(),
    ];

    this._serialParentEvents.next(combinedEventsWithoutDuplicates);
  }

  get getSerialParentEvents$() {
    return this._serialParentEvents;
  }

  public getSerialParentEvent(serialEventId: string) {
    return this._serialParentEvents
      .getValue()
      ?.find(e => e.id === serialEventId);
  }

  public setUpcomingEvents(events: SaEvent[]) {
    this._upcomingEvents.next(events);
  }

  get upcomingEvents$() {
    return this._upcomingEvents.asObservable();
  }

  get upcomingEvents() {
    return this._upcomingEvents.value;
  }

  public setPastEvents(events: SaEvent[]) {
    this._pastEvents.next(events);
  }

  get pastEvents$() {
    return this._pastEvents.asObservable();
  }

  public setMyEvents(events: SaEvent[]) {
    this._myEvents.next(events);
  }

  get myEvents$() {
    return this._myEvents.asObservable();
  }

  get myUpcomingEvents$() {
    const now = firestore.Timestamp.fromDate(new Date());
    return this._myEvents.pipe(
      map((events: SaEvent[]) =>
        events.filter(event => {
          return event?.start?.seconds > now.seconds;
        })
      )
    );
  }

  get myPastEvents$() {
    const now = firestore.Timestamp.fromDate(new Date());
    return this._myEvents.pipe(
      map((events: SaEvent[]) =>
        events.filter(event => {
          return event?.start?.seconds < now.seconds;
        })
      )
    );
  }

  public getEventsAsAllTrainerRoles$(): Observable<SaEvent[]> {
    return combineLatest([
      this.getEventsAsRoles$([
        SA_EVENT_PARTICIPANT_ROLE.TRAINER,
        SA_EVENT_PARTICIPANT_ROLE.CO_TRAINER,
      ]),
      this.myUpcomingEvents$,
    ]).pipe(
      map(([participants, events]) => {
        return participants
          .map(trainer => events?.find(event => event.id === trainer.eventId))
          .filter(Boolean)
          .sort((a, b) => a.start.toMillis() - b.start.toMillis());
      })
    );
  }

  public getEventsAs$(role: SA_EVENT_PARTICIPANT_ROLE): Observable<SaEvent[]> {
    return combineLatest([
      this.getEventParticipantsAs$(role),
      this.myUpcomingEvents$,
    ]).pipe(
      map(([participants, events]) => {
        return participants
          .map(trainer => events?.find(event => event.id === trainer.eventId))
          .filter(Boolean)
          .sort((a, b) => a.start.toMillis() - b.start.toMillis());
      })
    );
  }

  private getEventsAsRoles$(roles: SA_EVENT_PARTICIPANT_ROLE[]) {
    return this._eventParticipants.pipe(
      map(participants =>
        participants.filter(
          participant => roles.indexOf(participant.role) !== -1
        )
      )
    );
  }

  public getIntervisionEventsAs$(
    role: SA_EVENT_PARTICIPANT_ROLE
  ): Observable<SaEvent[]> {
    return combineLatest([
      this.getEventParticipantsAs$(role),
      this.myUpcomingIntervisionEvents$,
    ]).pipe(
      map(([participants, events]) => {
        return participants
          .map(trainer => events?.find(event => event.id === trainer.eventId))
          .filter(Boolean);
      })
    );
  }

  public setEventParticipants(eventParticipants: SaEventParticipant[]) {
    this._eventParticipants.next(eventParticipants);
  }

  get eventParticipants$() {
    return this._eventParticipants.asObservable();
  }

  private getEventParticipantsAs$(role: SA_EVENT_PARTICIPANT_ROLE) {
    return this._eventParticipants.pipe(
      map(participants =>
        participants.filter(participant => participant.role === role)
      )
    );
  }

  public setUserFavorites(userFavorites: SaExerciseUserFavorite[]) {
    this._userFavorites.next(userFavorites);
  }

  get userFavorites$() {
    return this._userFavorites.asObservable();
  }

  public setUserExerciseFavorites(exerciseFavorites: SaExercise[]) {
    this._userExerciseFavorites.next(exerciseFavorites);
  }

  get userExerciseFavorites$() {
    return this._userExerciseFavorites.asObservable();
  }

  /**
   *
   * HOME CONTENT
   *
   */

  public addFeatureListItem(item: SaFeature) {
    const curHomeContent = this._homeContent.getValue();
    curHomeContent.features.push(item);
    this._homeContent.next(curHomeContent);
  }

  public addQuote(item: SaQuote) {
    const curHomeContent = this._homeContent.getValue();
    curHomeContent.quotes.push(item);
    this._homeContent.next(curHomeContent);
  }

  public setHeroSrc(src: string) {
    const curHomeContent = this._homeContent.getValue();
    curHomeContent.hero = src;
    this._homeContent.next(curHomeContent);
  }

  public get homeContent$(): Observable<SaHomeContent> {
    return this._homeContent.asObservable();
  }

  /**
   *
   * GDPR Statement
   *
   */

  public addGdprStatement(renderedContent: string) {
    this._gdprStatement.next(renderedContent);
  }

  public get gdprStatement$(): Observable<string> {
    return this._gdprStatement.asObservable();
  }

  /**
   *
   * DOWNLOADS
   *
   */

  public addDownloadItem(item: SaDownload) {
    const curDownloads = this._downloads.getValue();
    if (curDownloads !== null) {
      curDownloads.push(item);
      this._downloads.next(curDownloads);
    } else {
      this._downloads.next([item]);
    }
  }

  public get downlods$(): Observable<SaDownload[]> {
    return this._downloads.asObservable();
  }

  public setDownlods(downloads: SaDownload[]): void {
    this._downloads.next(downloads);
  }

  /**
   *
   * FAQS
   *
   */

  public addUserFaqItem(item: SaFaq) {
    const curFaqs = this._userFaqs.getValue();
    curFaqs.push(item);
    this._userFaqs.next(curFaqs);
  }

  public get userFaqs$(): Observable<SaFaq[]> {
    return this._userFaqs.asObservable();
  }

  public setFaqItems(faqs: SaFaq[]) {
    this._faqs.next(faqs);
  }

  public get faqs$(): Observable<SaFaq[]> {
    return this._faqs.asObservable();
  }

  public addTrainerFaqItem(item: SaFaq) {
    const curFaqs = this._trainerFaqs.getValue();
    curFaqs.push(item);
    this._trainerFaqs.next(curFaqs);
  }

  public get trainerFaqs$(): Observable<SaFaq[]> {
    return this._trainerFaqs.asObservable();
  }

  /**
   *
   *  GLOBAL SETTINGS
   *
   */

  public setHomeLocations(locations: SaHomeLocation[]) {
    this._homeLocations.next(locations);
  }

  public get homeLocations$(): Observable<SaHomeLocation[]> {
    return this._homeLocations.asObservable();
  }

  /**
   *
   *  SEARCH
   *
   */
  public setGroupInputSearch(search: string) {
    this._groupInputSearch.next(search);
  }

  public get groupInputSearch$(): Observable<string> {
    return this._groupInputSearch.asObservable();
  }

  public setEventInputSearch(search: string) {
    this._eventInputSearch.next(search);
  }

  public get eventInputSearch$(): Observable<string> {
    return this._eventInputSearch.asObservable();
  }
}
