import { Injectable, NgZone } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import {
  CreateExerciseDTO,
  SaExercise,
  SaExerciseUserFavorite,
  SaExerciseUserFavoriteDTO,
  SaUser,
  SA_COLLECTIONS,
  SA_EXERCISE_STATUS,
  SA_NAVIGATION_PATH,
  SA_QUERY_PARAMS,
  TOAST_TYPE,
  UpdateExerciseDTO,
} from 'shared/interfaces';
import { ToastService } from 'shared/modules/toast-controller/services/toast.service';
import { StoreService } from '../store/store.service';

@Injectable({
  providedIn: 'root',
})
export class ExerciseService {
  private createExerciseLoading = new BehaviorSubject<boolean>(null);
  private updateExerciseLoading = new BehaviorSubject<boolean>(null);

  constructor(
    private db: AngularFirestore,
    private toastService: ToastService,
    private functions: AngularFireFunctions,
    private router: Router,
    private store: StoreService,
    private zone: NgZone
  ) {}

  public createExerciseLoading$() {
    return this.createExerciseLoading;
  }

  public updateExerciseLoading$() {
    return this.updateExerciseLoading;
  }

  public fetchExercise(id: string): Observable<SaExercise> {
    return this.db
      .collection(SA_COLLECTIONS.EXERCISES)
      .doc<SaExercise>(id)
      .valueChanges();
  }

  public async getExercises(
    locale: string,
    titleSearch?: string
  ): Promise<Map<string, SaExercise>> {
    const exercisesMap: Map<string, SaExercise> = new Map();

    const exercisesRef = await this.db
      .collection(SA_COLLECTIONS.EXERCISES)
      .ref.where(SA_QUERY_PARAMS.STATUS, '==', SA_EXERCISE_STATUS.ACTIVE)
      .where(SA_QUERY_PARAMS.LOCALE, '==', locale);

    await exercisesRef.get().then(querySnapshot => {
      querySnapshot.docs.forEach(doc => {
        const exercise: SaExercise = doc.data() as SaExercise;
        if (titleSearch) {
          if (
            exercise.title
              .toLocaleLowerCase()
              .indexOf(titleSearch.toLowerCase()) > -1
          ) {
            exercisesMap.set(exercise.id, exercise);
          }
        } else {
          exercisesMap.set(exercise.id, exercise);
        }
      });
    });

    return exercisesMap;
  }

  public createExercise(createExerciseDTO: CreateExerciseDTO) {
    this.createExerciseLoading.next(true);
    this.functions
      .httpsCallable('createExercise')(createExerciseDTO)
      .subscribe(
        (exercise: SaExercise) => {
          this.toastService.emitToast({
            type: TOAST_TYPE.SUCCESS,
            text: 'successfully created exercise',
          });

          this.createExerciseLoading.next(null);

          this.zone.run(() => {
            this.router.navigate([
              this.store.locale,
              SA_NAVIGATION_PATH.EXERCISES,
              exercise.id,
            ]);
          });
        },
        error => {
          console.error(error);
          this.createExerciseLoading.next(null);
          this.toastService.emitToast({
            type: TOAST_TYPE.ERROR,
            text: 'taost_something_went_wrong',
          });
        }
      );
  }

  public updateExerciseStatus(
    updateExerciseDTO: UpdateExerciseDTO
  ): Observable<SaExercise> {
    return this.functions.httpsCallable('updateExercise')(updateExerciseDTO);
  }

  public updateExercise(updateExerciseDTO: UpdateExerciseDTO) {
    this.updateExerciseLoading.next(true);
    this.functions
      .httpsCallable('updateExercise')(updateExerciseDTO)
      .subscribe(
        (exercise: SaExercise) => {
          this.toastService.emitToast({
            type: TOAST_TYPE.SUCCESS,
            text: 'successfully updated exercise',
          });

          this.updateExerciseLoading.next(null);
          this.zone.run(() => {
            this.router.navigate([
              this.store.locale,
              SA_NAVIGATION_PATH.EXERCISES,
              exercise.id,
            ]);
          });
        },
        error => {
          console.error(error);
          this.toastService.emitToast({
            type: TOAST_TYPE.ERROR,
            text: 'taost_something_went_wrong',
          });
        }
      );
  }

  public addVideo(addExerciseVideoDTO: UpdateExerciseDTO) {
    return this.functions.httpsCallable('updateExercise')(addExerciseVideoDTO);
  }

  public fetchMyUserFavorites() {
    this.store.user$
      .pipe(
        filter(Boolean),
        switchMap((user: SaUser) => {
          return this.db
            .collectionGroup<SaExerciseUserFavorite>(
              SA_COLLECTIONS.USER_FAVORITES,
              ref => ref.where(SA_QUERY_PARAMS.USER_ID, '==', user.id)
            )
            .valueChanges();
        })
      )
      .subscribe(userFavorite => {
        this.store.setUserFavorites(userFavorite);
      });
  }

  public fetchMyFavorites(locale: string) {
    this.store.userFavorites$
      .pipe(
        filter(Boolean),
        switchMap((userFavorites: SaExerciseUserFavorite[]) =>
          combineLatest(
            userFavorites.map(userFavorite =>
              this.db
                .collection(SA_COLLECTIONS.EXERCISES)
                .doc<SaExercise>(userFavorite.exerciseId)
                .valueChanges()
            )
          )
        )
      )
      .subscribe(exercises => {
        const localeExercises = exercises.filter(exercise => {
          return exercise.locale === locale;
        });
        this.store.setUserExerciseFavorites(localeExercises);
      });
  }

  public addUserFavorite(
    exerciseUserFavoriteDTO: SaExerciseUserFavoriteDTO
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.functions
        .httpsCallable('addUserFavorite')(exerciseUserFavoriteDTO)
        .subscribe(
          (exerciseUserFavorit: SaExerciseUserFavorite) => {
            this.toastService.emitToast({
              type: TOAST_TYPE.SUCCESS,
              text: 'successfully added bookmark',
            });
            resolve(true);
          },
          error => {
            console.log(error);
            this.toastService.emitToast({
              type: TOAST_TYPE.ERROR,
              text: 'taost_something_went_wrong',
            });
            reject(false);
          }
        );
    });
  }

  public removeUserFavorite(
    exerciseUserFavoriteDTO: SaExerciseUserFavoriteDTO
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.functions
        .httpsCallable('removeUserFavorite')(exerciseUserFavoriteDTO)
        .subscribe(
          (exerciseUserFavorit: SaExerciseUserFavorite) => {
            this.toastService.emitToast({
              type: TOAST_TYPE.SUCCESS,
              text: 'successfully removed bookmark',
            });
            resolve(true);
          },
          error => {
            console.log(error);
            this.toastService.emitToast({
              type: TOAST_TYPE.ERROR,
              text: 'taost_something_went_wrong',
            });
            reject(false);
          }
        );
    });
  }
}
