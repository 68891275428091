import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Router } from '@angular/router';
import { firestore } from 'firebase';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import {
  CreateGroupDTO,
  DeleteGroupDTO,
  SaGroup,
  SaGroupMember,
  SaUser,
  SA_COLLECTIONS,
  SA_GROUP_MEMBER_ROLE,
  SA_GROUP_MEMBER_STATUS,
  SA_GROUP_STATUS,
  SA_NAVIGATION_PATH,
  SA_QUERY_PARAMS,
  TOAST_TYPE,
  UpdateGroupDTO,
  UpdateGroupMemberStatusDTO,
} from 'shared/interfaces';
import {
  MODAL_ACTION_TYPE,
  MODAL_TYPE,
} from 'shared/interfaces/modal.interface';
import { ModalService } from 'shared/modules/modal-controller/services/modal.service';
import { ToastService } from 'shared/modules/toast-controller/services/toast.service';
import { NotificationService } from '../notification/notification.service';
import { StoreService } from '../store/store.service';

@Injectable({
  providedIn: 'root',
})
export class GroupService {
  private createGroupLoading = new BehaviorSubject<boolean>(null);
  private joinGroupLoading = new BehaviorSubject<string>(null);
  private leaveGroupLoading = new BehaviorSubject<string>(null);
  private deleteGroupLoading = new BehaviorSubject<string>(null);

  constructor(
    private store: StoreService,
    private notificationService: NotificationService,
    private modalService: ModalService,
    private toastService: ToastService,
    private db: AngularFirestore,
    private functions: AngularFireFunctions,
    private router: Router
  ) {}

  public init() {}

  public createGroupLoading$() {
    return this.createGroupLoading;
  }

  public joinGroupLoading$() {
    return this.joinGroupLoading;
  }

  public leaveGroupLoading$() {
    return this.leaveGroupLoading;
  }

  public deleteGroupLoading$() {
    return this.deleteGroupLoading;
  }

  public fetchMyGroupMembers() {
    this.store.user$
      .pipe(
        filter(Boolean),
        switchMap((user: SaUser) => {
          return this.db
            .collectionGroup<SaGroupMember>(SA_COLLECTIONS.GROUP_MEMBERS, ref =>
              ref
                .where(SA_QUERY_PARAMS.USER_ID, '==', user.id)
                .where(
                  SA_QUERY_PARAMS.STATUS,
                  '==',
                  SA_GROUP_MEMBER_STATUS.ACCEPTED
                )
            )
            .valueChanges();
        })
      )
      .subscribe(groupMembers => {
        this.store.setGroupMembers(groupMembers);
      });
  }

  public fetchMyPendingGroupMemberships() {
    this.store.user$
      .pipe(
        filter(Boolean),
        switchMap((user: SaUser) => {
          return this.db
            .collectionGroup<SaGroupMember>(SA_COLLECTIONS.GROUP_MEMBERS, ref =>
              ref
                .where(SA_QUERY_PARAMS.USER_ID, '==', user.id)
                .where(
                  SA_QUERY_PARAMS.STATUS,
                  '==',
                  SA_GROUP_MEMBER_STATUS.WAITING_FOR_ACCEPT
                )
            )
            .valueChanges();
        })
      )
      .subscribe(groupMembers => {
        this.store.setPendingGroupMemberships(groupMembers);
      });
  }

  public fetchActiveGroups(locale: string) {
    this.db
      .collection(SA_COLLECTIONS.GROUPS)
      .ref.where(SA_QUERY_PARAMS.STATUS, '==', SA_GROUP_STATUS.ACTIVE)
      .where(SA_QUERY_PARAMS.LOCALE, '==', locale)
      .get()
      .then(querySnapshot => {
        const groups: SaGroup[] = [];
        querySnapshot.forEach(doc => {
          groups.push(doc.data() as SaGroup);
        });
        this.store.setActiveGroups(groups);
      })
      .catch(error => {
        console.error('Error getting documents: ', error);
      });
  }

  public async getPendingGroupMembers(
    groupId: string
  ): Promise<SaGroupMember[]> {
    return this.db
      .collection(SA_COLLECTIONS.GROUPS)
      .doc(groupId)
      .collection(SA_COLLECTIONS.GROUP_MEMBERS)
      .ref.where(
        SA_QUERY_PARAMS.STATUS,
        '==',
        SA_GROUP_MEMBER_STATUS.WAITING_FOR_ACCEPT
      )
      .get()
      .then(querySnapshot => {
        const groupMembers: SaGroupMember[] = [];
        querySnapshot.forEach(doc => {
          groupMembers.push(doc.data() as SaGroupMember);
        });
        return groupMembers;
      })
      .catch(error => {
        console.error('Error getting documents: ', error);
        return [];
      });
  }

  public fetchMyGroups() {
    this.store.groupMembers$
      .pipe(
        filter(Boolean),
        switchMap((groupMembers: SaGroupMember[]) =>
          combineLatest(
            groupMembers.map(member =>
              this.db
                .collection(SA_COLLECTIONS.GROUPS)
                .doc<SaGroup>(member.groupId)
                .valueChanges()
            )
          )
        )
      )
      .subscribe((groups: SaGroup[]) => {
        const locale = this.store.locale;
        const activeLocalGroups: SaGroup[] = groups.filter(group => {
          return (
            group?.locale === locale && group?.status === SA_GROUP_STATUS.ACTIVE
          );
        });
        this.store.setGroups(activeLocalGroups);
      });
  }

  public async getUpcomingGroupEventIds(groupId: string) {
    const eventIds: string[] = [];
    const now = firestore.Timestamp.fromDate(new Date());

    const eventsRef = await this.db
      .collection(SA_COLLECTIONS.EVENTS)
      .ref.where(SA_QUERY_PARAMS.GROUP_ID, '==', groupId)
      .where(SA_QUERY_PARAMS.START, '>', now)
      .orderBy(SA_QUERY_PARAMS.START, 'asc');

    await eventsRef.get().then(querySnapshot => {
      querySnapshot.docs.forEach(doc => {
        eventIds.push(doc.id);
      });
    });
    return eventIds;
  }

  public async getGroupIDsByZip(zip: string) {
    const groupIds: string[] = [];

    const groupsRef = await this.db
      .collection(SA_COLLECTIONS.GROUPS)
      .ref.where(SA_QUERY_PARAMS.ZIP, '==', zip)
      .where(SA_QUERY_PARAMS.LOCALE, '==', this.store.locale)
      .where(SA_QUERY_PARAMS.STATUS, '==', SA_GROUP_STATUS.ACTIVE);

    await groupsRef.get().then(querySnapshot => {
      querySnapshot.docs.forEach(doc => {
        groupIds.push(doc.id);
      });
    });
    return groupIds;
  }

  public async getGroupIDsByLocation(serach: string) {
    const groupIds: string[] = [];

    const groupsRef = await this.db
      .collection(SA_COLLECTIONS.GROUPS)
      .ref.where(SA_QUERY_PARAMS.LOCALE, '==', this.store.locale)
      .where(SA_QUERY_PARAMS.STATUS, '==', SA_GROUP_STATUS.ACTIVE);

    await groupsRef.get().then(querySnapshot => {
      querySnapshot.docs.forEach(doc => {
        const group: SaGroup = doc.data() as SaGroup;
        if (
          group.location.city
            .toLocaleLowerCase()
            .indexOf(serach.toLowerCase()) > -1
        ) {
          groupIds.push(doc.id);
        }
      });
    });
    return groupIds;
  }

  public async getGroupIDsByTitle(serach: string) {
    const groupIds: string[] = [];

    const groupsRef = await this.db
      .collection(SA_COLLECTIONS.GROUPS)
      .ref.where(SA_QUERY_PARAMS.LOCALE, '==', this.store.locale)
      .where(SA_QUERY_PARAMS.STATUS, '==', SA_GROUP_STATUS.ACTIVE);

    await groupsRef.get().then(querySnapshot => {
      querySnapshot.docs.forEach(doc => {
        const group: SaGroup = doc.data() as SaGroup;
        if (
          group.title.toLocaleLowerCase().indexOf(serach.toLowerCase()) > -1
        ) {
          groupIds.push(doc.id);
        }
      });
    });
    return groupIds;
  }

  public fetchGroup(id: string) {
    return this.db
      .collection(SA_COLLECTIONS.GROUPS)
      .doc<SaGroup>(id)
      .valueChanges();
  }

  public fetchGroupMember(groupId: string, userId: string) {
    return this.db
      .collection(SA_COLLECTIONS.GROUPS)
      .doc<SaGroup>(groupId)
      .collection(SA_COLLECTIONS.GROUP_MEMBERS)
      .doc<SaGroupMember>(userId)
      .valueChanges();
  }

  public async getGroupMemberIDs(
    groupId: string,
    role: SA_GROUP_MEMBER_ROLE,
    status: SA_GROUP_MEMBER_STATUS = SA_GROUP_MEMBER_STATUS.ACCEPTED
  ): Promise<string[]> {
    const groupMemberIds: string[] = [];

    const groupMembersRef = await this.db
      .collection(SA_COLLECTIONS.GROUPS)
      .doc<SaGroup>(groupId)
      .collection(SA_COLLECTIONS.GROUP_MEMBERS)
      .ref.where(SA_QUERY_PARAMS.ROLE, '==', role)
      .where(SA_QUERY_PARAMS.STATUS, '==', status);

    await groupMembersRef.get().then(querySnapshot => {
      querySnapshot.docs.forEach(doc => {
        groupMemberIds.push(doc.id);
      });
    });
    return groupMemberIds;
  }

  public createGroup(groupDto: CreateGroupDTO) {
    this.createGroupLoading.next(true);
    this.functions
      .httpsCallable('createGroup')(groupDto)
      .subscribe(
        group => {
          this.toastService.emitToast({
            type: TOAST_TYPE.SUCCESS,
            text: 'successfully created group',
          });
          this.createGroupLoading.next(null);
          this.router.navigate([
            this.store.locale,
            SA_NAVIGATION_PATH.GROUPS,
            group.id,
          ]);
          this.fetchActiveGroups(this.store.locale);
        },
        error => {
          console.error(error);
          this.toastService.emitToast({
            type: TOAST_TYPE.ERROR,
            text: 'taost_something_went_wrong',
          });
        }
      );
  }

  public updateGroup(groupDto: UpdateGroupDTO) {
    this.createGroupLoading.next(true);
    this.functions
      .httpsCallable('updateGroup')(groupDto)
      .subscribe(
        group => {
          this.toastService.emitToast({
            type: TOAST_TYPE.SUCCESS,
            text: 'successfully updated group',
          });
          this.createGroupLoading.next(null);
          this.router.navigate([
            this.store.locale,
            SA_NAVIGATION_PATH.GROUPS,
            group.id,
          ]);
          this.fetchActiveGroups(this.store.locale);
        },
        error => {
          console.error(error);
          this.toastService.emitToast({
            type: TOAST_TYPE.ERROR,
            text: 'taost_something_went_wrong',
          });
        }
      );
  }

  public deleteGroup(groupId: string) {
    const user: SaUser = this.store.user$.getValue();
    if (user) {
      this.deleteGroupLoading.next(groupId);
      const deleteGroupDTO: DeleteGroupDTO = {
        groupId,
        ownerId: user.id,
      };

      this.functions
        .httpsCallable('deleteGroup')(deleteGroupDTO)
        .subscribe(
          res => {
            this.fetchActiveGroups(this.store.locale);
            this.fetchMyGroups();
            this.toastService.emitToast({
              type: TOAST_TYPE.SUCCESS,
              text: 'successfully deleted group',
            });
            this.deleteGroupLoading.next(TOAST_TYPE.SUCCESS);
          },
          error => {
            console.error(error);
            this.toastService.emitToast({
              type: TOAST_TYPE.ERROR,
              text: 'taost_something_went_wrong',
            });
          }
        );
    }
  }

  public addCoTrainer(groupId: string, trainerId: string) {
    return this.functions.httpsCallable('addGroupTrainer')({
      groupId,
      userId: trainerId,
      status: SA_GROUP_MEMBER_STATUS.ACCEPTED,
      role: SA_GROUP_MEMBER_ROLE.CO_TRAINER,
    });
  }

  public async joinGroup(groupId: string) {
    const user: SaUser = this.store.user$.getValue();
    if (!user) {
      this.modalService.emitModal({
        type: MODAL_TYPE.INFO,
        text: 'modal_not_logged_in',
        action: {
          actionLabel: 'login_button',
          type: MODAL_ACTION_TYPE.NAVIGATE,
          navigationItem: {
            commands: [SA_NAVIGATION_PATH.LOGIN],
            extras: {
              state: {
                redirect: this.router.url,
              },
            },
          },
        },
      });
      return;
    }
    this.joinGroupLoading.next(groupId);

    const group = await this.getGroupData(groupId);

    if (group) {
      let initialStatus = SA_GROUP_MEMBER_STATUS.WAITING_FOR_ACCEPT;
      if (group.isPublic) {
        initialStatus = SA_GROUP_MEMBER_STATUS.ACCEPTED;
      }

      this.functions
        .httpsCallable('addGroupMember')({
          groupId,
          userId: user.id,
          status: initialStatus,
          role: SA_GROUP_MEMBER_ROLE.MEMBER,
        })
        .subscribe(
          async () => {
            const toastText =
              initialStatus === SA_GROUP_MEMBER_STATUS.ACCEPTED
                ? 'successfully joined'
                : 'membership requested';
            this.toastService.emitToast({
              type: TOAST_TYPE.SUCCESS,
              text: toastText,
            });
            if (initialStatus === SA_GROUP_MEMBER_STATUS.WAITING_FOR_ACCEPT) {
              this.notificationService.generatePendingGroupMembershipRequestUserNotification(
                group
              );
            }

            this.joinGroupLoading.next(null);
          },
          error => {
            console.error(error);
            this.toastService.emitToast({
              type: TOAST_TYPE.ERROR,
              text: 'taost_something_went_wrong',
            });
          }
        );
    }
  }

  public async getGroupData(groupId: string): Promise<SaGroup> {
    return this.db
      .collection(SA_COLLECTIONS.GROUPS)
      .doc(groupId)
      .get()
      .toPromise()
      .then(doc => {
        if (doc.exists) {
          return doc.data() as SaGroup;
        }
        return null;
      })
      .catch(error => {
        return null;
      });
  }

  public removeGroupMember(groupId: string, userId: string) {
    return this.functions.httpsCallable('removeGroupMember')({
      groupId,
      userId,
    });
  }

  public leaveGroup(groupId: string) {
    const user: SaUser = this.store.user$.getValue();
    if (!user) {
      this.modalService.emitModal({
        type: MODAL_TYPE.INFO,
        text: 'modal_not_logged_in',
        action: {
          actionLabel: 'login_button',
          type: MODAL_ACTION_TYPE.NAVIGATE,
          navigationItem: {
            commands: [SA_NAVIGATION_PATH.LOGIN],
            extras: {
              state: {
                redirect: this.router.url,
              },
            },
          },
        },
      });
      return;
    }

    this.leaveGroupLoading.next(groupId);
    this.functions
      .httpsCallable('removeGroupMember')({
        groupId,
        userId: user.id,
      })
      .subscribe(
        (group: SaGroup) => {
          this.toastService.emitToast({
            type: TOAST_TYPE.SUCCESS,
            text: 'successfully left group',
          });
          this.leaveGroupLoading.next(null);
        },
        error => {
          console.error(error);
          this.toastService.emitToast({
            type: TOAST_TYPE.ERROR,
            text: 'taost_something_went_wrong',
          });
        }
      );
  }

  public updateMembership(
    userId: string,
    groupId: string,
    status: SA_GROUP_MEMBER_STATUS
  ) {
    const updateGroupMemberStatusDTO: UpdateGroupMemberStatusDTO = {
      groupId,
      userId,
      status,
    };
    return this.functions.httpsCallable('updateGroupMemberStatus')(
      updateGroupMemberStatusDTO
    );
  }

  public async inSameGroup(
    userId: string,
    authUserId: string
  ): Promise<boolean> {
    const userGroups = await this.getUserGroups(userId);
    const authUserGroups = await this.getUserGroups(authUserId);
    const groupIntersection = userGroups?.filter(value =>
      authUserGroups?.includes(value)
    );

    return Promise.resolve(groupIntersection?.length > 0);
  }

  private async getUserGroups(userId: string) {
    const groupIds = [];
    const groupsRef = this.db
      .collectionGroup<SaGroupMember>(SA_COLLECTIONS.GROUP_MEMBERS, ref =>
        ref
          .where(SA_QUERY_PARAMS.USER_ID, '==', userId)
          .where(SA_QUERY_PARAMS.STATUS, '==', SA_GROUP_MEMBER_STATUS.ACCEPTED)
      )
      .get()
      .toPromise();

    await groupsRef.then(querySnapshot => {
      querySnapshot.docs.forEach(doc => {
        const data = doc.data();
        if (!groupIds?.find(e => e === data.groupId)) {
          groupIds.push(data.groupId);
        }
      });
    });

    return groupIds;
  }
}
