export enum TOAST_TYPE {
  SUCCESS = 'success',
  INFO = 'info',
  ERROR = 'error',
}

export interface SaToast {
  type: TOAST_TYPE;
  text: string;
  timestamp?: number;
}
