export enum SA_COLLECTIONS {
  GROUP_MEMBERS = 'groupMembers',
  GROUPS = 'groups',
  EVENTS = 'events',
  INTERVISION_EVENTS = 'intervisionEvents',
  EVENT_PARTICIPANTS = 'eventParticipants',
  USERS = 'users',
  USER_FEATURES = 'userFeatures',
  USER_NOTIFICATION_SETTINGS = 'userNotificationSettings',
  NOTIFICATIONS = 'notifications',
  USER_NOTIFICATIONS = 'userNotifications',
  EXERCISES = 'exercises',
  USER_FAVORITES = 'userFavorites',
  TRAININGS = 'trainings',
  FORUM_TOPICS = 'forumTopics',
}

export enum SA_QUERY_PARAMS {
  EMAIL = 'email',
  GROUP_ID = 'groupId',
  LOCALE = 'locale',
  STATUS = 'status',
  START = 'start',
  USER_ID = 'userId',
  ZIP = 'location.zip',
  ROLE = 'role',
  ROLES = 'roles',
  ID = 'id',
  OWNER_ID = 'ownerId',
}
