import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Router } from '@angular/router';
import { subYears } from 'date-fns';
import { firestore } from 'firebase';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import {
  CreateEventDTO,
  CreateSerialEventDTO,
  DeleteEventDTO,
  SaEvent,
  SaEventParticipant,
  SaUser,
  SA_COLLECTIONS,
  SA_EVENT_PARTICIPANT_ROLE,
  SA_EVENT_PARTICIPANT_STATUS,
  SA_EVENT_STATUS,
  SA_GROUP_MEMBER_ROLE,
  SA_NAVIGATION_PATH,
  SA_QUERY_PARAMS,
  TOAST_TYPE,
  UpdateEventDTO,
  UpdateEventParticipantStatusDTO,
} from 'shared/interfaces';
import {
  MODAL_ACTION_TYPE,
  MODAL_TYPE,
} from 'shared/interfaces/modal.interface';
import { ModalService } from 'shared/modules/modal-controller/services/modal.service';
import { ToastService } from 'shared/modules/toast-controller/services/toast.service';
import { GroupService } from '../group/group.service';
import { NotificationService } from '../notification/notification.service';
import { StoreService } from '../store/store.service';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  private createEventLoading = new BehaviorSubject<boolean>(null);
  private joinEventLoading = new BehaviorSubject<string>(null);
  private leaveEventLoading = new BehaviorSubject<string>(null);
  private deleteEventLoading = new BehaviorSubject<string>(null);
  private eventActionCompleted = new BehaviorSubject<void>(null);

  constructor(
    private store: StoreService,
    private groupService: GroupService,
    private modalService: ModalService,
    private toastService: ToastService,
    private db: AngularFirestore,
    private functions: AngularFireFunctions,
    private router: Router,
    private notificationService: NotificationService
  ) {}

  public init() {}

  public createEventLoading$() {
    return this.createEventLoading;
  }

  public joinEventLoading$() {
    return this.joinEventLoading;
  }

  public leaveEventLoading$() {
    return this.leaveEventLoading;
  }

  public deleteEventLoading$() {
    return this.deleteEventLoading;
  }

  public eventActionCompleted$() {
    return this.eventActionCompleted;
  }

  public fetchUpcomingEvents(locale: string) {
    const now = firestore.Timestamp.fromDate(new Date());

    this.db
      .collection(SA_COLLECTIONS.EVENTS)
      .ref.where(SA_QUERY_PARAMS.START, '>', now)
      .where(SA_QUERY_PARAMS.STATUS, '==', SA_EVENT_STATUS.ACTIVE)
      .where(SA_QUERY_PARAMS.LOCALE, '==', locale)
      .orderBy(SA_QUERY_PARAMS.START, 'asc')
      .get()
      .then(querySnapshot => {
        const events: SaEvent[] = [];
        querySnapshot.forEach(doc => {
          events.push(doc.data() as SaEvent);
        });

        // set parent events
        const parentEvents = events.filter(e => e.id === e.serialEventId);
        this.store.setSerialParentEvents(parentEvents);
        this.store.setUpcomingEvents(this.getSerialEventData(events));
      })
      .catch(error => {
        console.error('Error getting documents: ', error);
      });
  }

  public fetchPastEvents(locale: string) {
    const nowDate = new Date();
    const now = firestore.Timestamp.fromDate(nowDate);
    const oneYearInPast = firestore.Timestamp.fromDate(subYears(nowDate, 1));

    this.store.user$
      .pipe(
        filter(Boolean),
        switchMap((user: SaUser) => {
          return this.db
            .collection<SaEvent>(SA_COLLECTIONS.EVENTS, ref =>
              ref
                .where(SA_QUERY_PARAMS.OWNER_ID, '==', user.id)
                .where(SA_QUERY_PARAMS.START, '<', now)
                .where(SA_QUERY_PARAMS.START, '>', oneYearInPast)
                .where(SA_QUERY_PARAMS.LOCALE, '==', locale)
                .where(SA_QUERY_PARAMS.STATUS, '==', SA_EVENT_STATUS.ACTIVE)
                .orderBy(SA_QUERY_PARAMS.START, 'asc')
            )
            .valueChanges();
        })
      )
      .subscribe(events => {
        // set parent events
        const parentEvents = events.filter(e => e.id === e.serialEventId);
        this.store.setSerialParentEvents(parentEvents);
        this.store.setPastEvents(this.getSerialEventData(events));
      });
  }

  public fetchMyEventParticipants() {
    this.store.user$
      .pipe(
        filter(Boolean),
        switchMap((user: SaUser) => {
          return this.db
            .collectionGroup<SaEventParticipant>(
              SA_COLLECTIONS.EVENT_PARTICIPANTS,
              ref =>
                ref
                  .where(SA_QUERY_PARAMS.USER_ID, '==', user.id)
                  .where(
                    SA_QUERY_PARAMS.STATUS,
                    '==',
                    SA_EVENT_PARTICIPANT_STATUS.ACCEPTED
                  )
            )
            .valueChanges();
        })
      )
      .subscribe(eventParticipants => {
        this.store.setEventParticipants(eventParticipants);
      });
  }

  public fetchMyPendingEventMemberships() {
    this.store.user$
      .pipe(
        filter(Boolean),
        switchMap((user: SaUser) => {
          return this.db
            .collectionGroup<SaEventParticipant>(
              SA_COLLECTIONS.EVENT_PARTICIPANTS,
              ref =>
                ref
                  .where(SA_QUERY_PARAMS.USER_ID, '==', user.id)
                  .where(
                    SA_QUERY_PARAMS.STATUS,
                    '==',
                    SA_EVENT_PARTICIPANT_STATUS.WAITING_FOR_ACCEPT
                  )
            )
            .valueChanges();
        })
      )
      .subscribe(eventParticipant => {
        this.store.setPendingEventMemberships(eventParticipant);
      });
  }

  public fetchMyEvents(customCollection?: SA_COLLECTIONS) {
    const eventCollection = customCollection
      ? customCollection
      : SA_COLLECTIONS.EVENTS;
    this.store.eventParticipants$
      .pipe(
        filter(Boolean),
        switchMap((eventParticipants: SaEventParticipant[]) =>
          combineLatest(
            eventParticipants.map(participant =>
              this.db
                .collection(eventCollection)
                .doc<SaEvent>(participant.eventId)
                .valueChanges()
            )
          )
        )
      )
      .subscribe((events: SaEvent[]) => {
        const locale = this.store.locale;
        const activeLocalEvents: SaEvent[] = events.filter(event => {
          return (
            event &&
            event.locale === locale &&
            event.status === SA_EVENT_STATUS.ACTIVE
          );
        });
        if (customCollection) {
          this.store.setMyIntervisionEvents(activeLocalEvents);
        } else {
          this.store.setMyEvents(this.getSerialEventData(activeLocalEvents));
        }
      });
  }

  public fetchEvent(
    id: string,
    customCollection?: SA_COLLECTIONS
  ): Observable<SaEvent | undefined> {
    const eventCollection = customCollection
      ? customCollection
      : SA_COLLECTIONS.EVENTS;

    const event$ = this.db
      .collection(eventCollection)
      .doc<SaEvent>(id)
      .valueChanges();

    return combineLatest([event$, this.store.getSerialParentEvents$]).pipe(
      map(([event, serialParentEvents]) => {
        if (
          serialParentEvents?.length > 0 &&
          !event.manipulated &&
          event.serialEventId
        ) {
          const parentEvent = this.store.getSerialParentEvent(
            event.serialEventId
          );
          if (parentEvent) {
            return this.getSerialEvent(event, parentEvent);
          }
        }
        return event;
      })
    );
  }

  public async getEventIDsByZip(
    zip: string,
    customCollection?: SA_COLLECTIONS
  ) {
    const eventIds: string[] = [];
    const now = firestore.Timestamp.fromDate(new Date());
    const eventCollection = customCollection
      ? customCollection
      : SA_COLLECTIONS.EVENTS;

    const eventsRef = await this.db
      .collection(eventCollection)
      .ref.where(SA_QUERY_PARAMS.ZIP, '==', zip)
      .where(SA_QUERY_PARAMS.STATUS, '==', SA_EVENT_STATUS.ACTIVE)
      .where(SA_QUERY_PARAMS.START, '>', now)
      .where(SA_QUERY_PARAMS.LOCALE, '==', this.store.locale)
      .orderBy(SA_QUERY_PARAMS.START, 'asc');

    await eventsRef.get().then(querySnapshot => {
      querySnapshot.docs.forEach(doc => {
        const event = doc.data() as SaEvent;
        if (event.id !== event.serialEventId) {
          eventIds.push(doc.id);
        }
      });
    });
    return eventIds;
  }

  public async getEventIDsByLocation(
    location: string,
    customCollection?: SA_COLLECTIONS
  ) {
    const eventIds: string[] = [];
    const now = firestore.Timestamp.fromDate(new Date());
    const eventCollection = customCollection
      ? customCollection
      : SA_COLLECTIONS.EVENTS;

    const eventsRef = await this.db
      .collection(eventCollection)
      .ref.where(SA_QUERY_PARAMS.START, '>', now)
      .where(SA_QUERY_PARAMS.STATUS, '==', SA_EVENT_STATUS.ACTIVE)
      .where(SA_QUERY_PARAMS.LOCALE, '==', this.store.locale)
      .orderBy(SA_QUERY_PARAMS.START, 'asc');

    await eventsRef.get().then(querySnapshot => {
      querySnapshot.docs.forEach(doc => {
        const event = doc.data() as SaEvent;
        if (
          event.location.city.toLowerCase().indexOf(location.toLowerCase()) >
            -1 &&
          event.id !== event.serialEventId
        ) {
          eventIds.push(doc.id);
        }
      });
    });
    return eventIds;
  }

  public async getEventIDsByTitle(
    serach: string,
    customCollection?: SA_COLLECTIONS
  ) {
    const eventIds: string[] = [];
    const now = firestore.Timestamp.fromDate(new Date());
    const eventCollection = customCollection
      ? customCollection
      : SA_COLLECTIONS.EVENTS;

    const eventsRef = await this.db
      .collection(eventCollection)
      .ref.where(SA_QUERY_PARAMS.LOCALE, '==', this.store.locale)
      .where(SA_QUERY_PARAMS.STATUS, '==', SA_EVENT_STATUS.ACTIVE)
      .where(SA_QUERY_PARAMS.START, '>', now)
      .orderBy(SA_QUERY_PARAMS.START, 'asc');

    await eventsRef.get().then(querySnapshot => {
      querySnapshot.docs.forEach(doc => {
        const event: SaEvent = doc.data() as SaEvent;
        if (
          event.title.toLocaleLowerCase().indexOf(serach.toLowerCase()) > -1 &&
          event.id !== event.serialEventId
        ) {
          eventIds.push(doc.id);
        }
      });
    });
    return eventIds;
  }

  public createEvent(createEventDTO: CreateEventDTO) {
    this.createEventLoading.next(true);
    this.functions
      .httpsCallable('createEvent')(createEventDTO)
      .subscribe(
        async (event: SaEvent) => {
          this.toastService.emitToast({
            type: TOAST_TYPE.SUCCESS,
            text: 'successfully created event',
          });
          const userIds = await this.groupService.getGroupMemberIDs(
            event.groupId,
            SA_GROUP_MEMBER_ROLE.MEMBER
          );
          this.notificationService.generateNewEventUserNotifications(
            event,
            userIds
          );
          this.createEventLoading.next(null);
          this.router.navigate([
            this.store.locale,
            SA_NAVIGATION_PATH.EVENTS,
            event.id,
          ]);
          this.fetchEvents();
        },
        error => {
          console.error(error);
          this.createEventLoading.next(null);
          this.toastService.emitToast({
            type: TOAST_TYPE.ERROR,
            text: 'taost_something_went_wrong',
          });
        }
      );
  }

  public updateEvent(updateEventDTO: UpdateEventDTO, eventRoute?: string) {
    this.createEventLoading.next(true);
    this.functions
      .httpsCallable('updateEvent')(updateEventDTO)
      .subscribe(
        (event: SaEvent) => {
          this.fetchEvents();
          this.toastService.emitToast({
            type: TOAST_TYPE.SUCCESS,
            text: 'successfully updated event',
          });

          this.createEventLoading.next(null);
          this.router.navigate([
            this.store.locale,
            SA_NAVIGATION_PATH.EVENTS,
            eventRoute || event.id,
          ]);
        },
        error => {
          console.error(error);
          this.createEventLoading.next(null);
          this.toastService.emitToast({
            type: TOAST_TYPE.ERROR,
            text: 'taost_something_went_wrong',
          });
        }
      );
  }

  public cancelEvent(eventId: string) {
    return this.functions
      .httpsCallable('cancelEvent')({ eventId })
      .pipe(tap(() => this.fetchEvents()));
  }

  public async duplicateEvent(orinalEvent: SaEvent): Promise<void> {
    const createEventDTO: CreateEventDTO = {
      groupId: orinalEvent.groupId,
      ownerId: orinalEvent.ownerId,
      title: `Copy: ${orinalEvent.title}`,
      description: orinalEvent.description ? orinalEvent.description : null,
      imageUrl: orinalEvent.imageUrl ? orinalEvent.imageUrl : null,
      startMillis: orinalEvent.start.toMillis(),
      endMillis: orinalEvent.end.toMillis(),
      locale: orinalEvent.locale,
      location: orinalEvent.location,
      directions: orinalEvent.directions ? orinalEvent.directions : '',
      price: orinalEvent.price,
      type: orinalEvent.type,
      status: orinalEvent.status,
      isMembersOnly: orinalEvent.isMembersOnly
        ? orinalEvent.isMembersOnly
        : false,
      groupSizeMin: orinalEvent.groupSizeMin ? orinalEvent.groupSizeMin : null,
      groupSize: orinalEvent.groupSize ? orinalEvent.groupSize : null,
      gallery: orinalEvent.gallery ? orinalEvent.gallery : null,
    };

    this.functions
      .httpsCallable('createEvent')(createEventDTO)
      .subscribe(
        async (event: SaEvent) => {
          this.toastService.emitToast({
            type: TOAST_TYPE.SUCCESS,
            text: 'successfully created event',
          });
          const userIds = await this.groupService.getGroupMemberIDs(
            event.groupId,
            SA_GROUP_MEMBER_ROLE.MEMBER
          );
          this.notificationService.generateNewEventUserNotifications(
            event,
            userIds
          );
          this.createEventLoading.next(null);
          this.router.navigate([
            this.store.locale,
            SA_NAVIGATION_PATH.EVENTS,
            event.id,
          ]);
        },
        error => {
          console.error(error);
          this.createEventLoading.next(null);
          this.toastService.emitToast({
            type: TOAST_TYPE.ERROR,
            text: 'taost_something_went_wrong',
          });
        }
      );
  }

  public async onEventCancelled(eventId: string) {
    const participantIds = await this.getEventParticipantIDs(
      eventId,
      SA_EVENT_PARTICIPANT_ROLE.PARTICIPANT
    );
    const trainerIds = await this.getEventParticipantIDs(
      eventId,
      SA_EVENT_PARTICIPANT_ROLE.TRAINER
    );
    const userIds = participantIds.concat(trainerIds);
    this.notificationService.generateEventCancelledUserNotifications(
      eventId,
      userIds
    );
  }

  public deleteEvent(eventId: string) {
    const user: SaUser = this.store.user$.getValue();
    if (user) {
      this.deleteEventLoading.next(eventId);
      const deleteEventDTO: DeleteEventDTO = {
        eventId,
        ownerId: user.id,
      };

      this.functions
        .httpsCallable('deleteEvent')(deleteEventDTO)
        .subscribe(
          res => {
            this.fetchEvents();
            this.toastService.emitToast({
              type: TOAST_TYPE.SUCCESS,
              text: 'successfully deleted event',
            });
            this.deleteEventLoading.next(TOAST_TYPE.SUCCESS);
          },
          error => {
            console.error(error);
            this.toastService.emitToast({
              type: TOAST_TYPE.ERROR,
              text: 'taost_something_went_wrong',
            });
          }
        );
    }
  }

  private fetchEvents(): void {
    this.fetchUpcomingEvents(this.store.locale);
    this.fetchPastEvents(this.store.locale);
    this.fetchMyEvents();
  }

  public removeEventParticipant(eventId: string, userId: string) {
    return this.functions.httpsCallable('removeEventParticipant')({
      eventId,
      userId,
    });
  }

  public unsubscribeFromEvent(eventId: string) {
    const user: SaUser = this.store.user$.getValue();
    if (!user) {
      this.modalService.emitModal({
        type: MODAL_TYPE.INFO,
        text: 'modal_not_logged_in',
        action: {
          actionLabel: 'login_button',
          type: MODAL_ACTION_TYPE.NAVIGATE,
          navigationItem: {
            commands: [SA_NAVIGATION_PATH.LOGIN],
            extras: {
              state: {
                redirect: this.router.url,
              },
            },
          },
        },
      });
      return;
    }

    this.leaveEventLoading.next(eventId);

    this.functions
      .httpsCallable('removeEventParticipant')({
        eventId,
        userId: user.id,
      })
      .subscribe(
        (event: SaEvent) => {
          this.toastService.emitToast({
            type: TOAST_TYPE.SUCCESS,
            text: 'successfully unsubscribed from event',
          });
          this.leaveEventLoading.next(null);
          this.eventActionCompleted.next();
        },
        error => {
          console.error(error);
          this.toastService.emitToast({
            type: TOAST_TYPE.ERROR,
            text: 'taost_something_went_wrong',
          });
        }
      );
  }

  public async getEventData(
    eventId: string,
    customCollection?: SA_COLLECTIONS
  ): Promise<SaEvent> {
    const eventCollection = customCollection
      ? customCollection
      : SA_COLLECTIONS.EVENTS;

    return this.db
      .collection(eventCollection)
      .doc(eventId)
      .get()
      .toPromise()
      .then(doc => {
        if (doc.exists) {
          return doc.data() as SaEvent;
        }
        return null;
      })
      .catch(error => {
        return null;
      });
  }

  public async joinEvent(eventId: string) {
    const user: SaUser = this.store.user$.getValue();
    if (!user) {
      this.modalService.emitModal({
        type: MODAL_TYPE.INFO,
        text: 'modal_not_logged_in',
        action: {
          actionLabel: 'login_button',
          type: MODAL_ACTION_TYPE.NAVIGATE,
          navigationItem: {
            commands: [SA_NAVIGATION_PATH.LOGIN],
            extras: {
              state: {
                redirect: this.router.url,
              },
            },
          },
        },
      });
      return;
    }
    this.joinEventLoading.next(eventId);

    const initialStatus = SA_EVENT_PARTICIPANT_STATUS.ACCEPTED;
    const event = await this.getEventData(eventId);
    if (event) {
      this.functions
        .httpsCallable('addEventParticipant')({
          eventId,
          userId: user.id,
          status: initialStatus,
          role: SA_EVENT_PARTICIPANT_ROLE.PARTICIPANT,
        })
        .subscribe(
          async () => {
            this.toastService.emitToast({
              type: TOAST_TYPE.SUCCESS,
              text: 'successfully joined event',
            });
            this.joinEventLoading.next(null);
            this.eventActionCompleted.next();
          },
          error => {
            console.error(error);
            this.toastService.emitToast({
              type: TOAST_TYPE.ERROR,
              text: 'taost_something_went_wrong',
            });
          }
        );
    }
  }

  public async getEventParticipantIDs(
    eventId: string,
    role: SA_EVENT_PARTICIPANT_ROLE,
    customCollection?: SA_COLLECTIONS
  ) {
    const eventParticipantIds: string[] = [];
    const eventCollection = customCollection
      ? customCollection
      : SA_COLLECTIONS.EVENTS;

    const eventParticipantRef = await this.db
      .collection(eventCollection)
      .doc<SaEvent>(eventId)
      .collection(SA_COLLECTIONS.EVENT_PARTICIPANTS)
      .ref.where(SA_QUERY_PARAMS.ROLE, '==', role);

    await eventParticipantRef.get().then(querySnapshot => {
      querySnapshot.docs.forEach(doc => {
        eventParticipantIds.push(doc.id);
      });
    });
    return eventParticipantIds;
  }

  public addCoTrainer(eventId: string, trainerId: string) {
    return this.functions.httpsCallable('addEventTrainer')({
      eventId,
      userId: trainerId,
      status: SA_EVENT_PARTICIPANT_STATUS.ACCEPTED,
      role: SA_EVENT_PARTICIPANT_ROLE.CO_TRAINER,
    });
  }

  public async getPendingEventMembers(
    eventId: string
  ): Promise<SaEventParticipant[]> {
    return this.db
      .collection(SA_COLLECTIONS.EVENTS)
      .doc(eventId)
      .collection(SA_COLLECTIONS.EVENT_PARTICIPANTS)
      .ref.where(
        SA_QUERY_PARAMS.STATUS,
        '==',
        SA_EVENT_PARTICIPANT_STATUS.WAITING_FOR_ACCEPT
      )
      .get()
      .then(querySnapshot => {
        const eventParticipants: SaEventParticipant[] = [];
        querySnapshot.forEach(doc => {
          eventParticipants.push(doc.data() as SaEventParticipant);
        });
        return eventParticipants;
      })
      .catch(error => {
        console.error('Error getting documents: ', error);
        return [];
      });
  }

  public updateMembership(
    userId: string,
    eventId: string,
    status: SA_EVENT_PARTICIPANT_STATUS
  ) {
    const updateEventParticipantStatusDTO: UpdateEventParticipantStatusDTO = {
      eventId,
      userId,
      status,
    };
    return this.functions.httpsCallable('updateEventParticipantStatus')(
      updateEventParticipantStatusDTO
    );
  }

  public createSerialEvents(createSerialEventDTO: CreateSerialEventDTO) {
    this.createEventLoading.next(true);
    this.functions
      .httpsCallable('createSerialEvent')(createSerialEventDTO)
      .subscribe(
        async (event: SaEvent) => {
          this.fetchEvents();
          this.toastService.emitToast({
            type: TOAST_TYPE.SUCCESS,
            text: 'successfully created event',
          });
          const userIds = await this.groupService.getGroupMemberIDs(
            event.groupId,
            SA_GROUP_MEMBER_ROLE.MEMBER
          );
          this.notificationService.generateNewEventUserNotifications(
            event,
            userIds
          );
          this.createEventLoading.next(null);
          this.router.navigate([
            this.store.locale,
            SA_NAVIGATION_PATH.EVENTS,
            event.id,
          ]);
        },
        error => {
          console.error(error);
          this.createEventLoading.next(null);
          this.toastService.emitToast({
            type: TOAST_TYPE.ERROR,
            text: 'taost_something_went_wrong',
          });
        }
      );
  }

  public updateSerialEvent(event: UpdateEventDTO, serialEventId: string): void {
    const parentEvent = this.store.getSerialParentEvent(serialEventId);
    if (!parentEvent) {
      this.toastService.emitToast({
        type: TOAST_TYPE.ERROR,
        text: 'taost_something_went_wrong',
      });
      return;
    }
    const start = new Date(event.startMillis);
    const end = new Date(event.endMillis);
    const parentStart = parentEvent.start.toDate();
    const parentEnd = parentEvent.end.toDate();
    const eventRoute = event.id;

    if (
      start.getHours() === parentStart.getHours() &&
      start.getMinutes() === parentStart.getMinutes() &&
      end.getHours() === parentEnd.getHours() &&
      end.getMinutes() === parentEnd.getMinutes()
    ) {
      // time has not changed! only update parent serial event
      event.id = parentEvent.id;
      this.updateEvent(event, eventRoute);
      return;
    }

    // time has changed! update each events date/time and parent serial event
    const events = this.store.upcomingEvents?.filter(
      e => e.serialEventId === parentEvent.serialEventId && !e.manipulated
    );
    events.push(parentEvent);
    const updateEvents = events.map(e => {
      return this.getUpdatedEvent(e, event, start, end);
    });

    this.updateSerialEvents(updateEvents, eventRoute);
  }

  private getUpdatedEvent(
    event: SaEvent,
    updateEvent: UpdateEventDTO,
    start: Date,
    end: Date
  ): UpdateEventDTO {
    const newEvent = Object.create(updateEvent);
    const startDate = event.start.toDate();
    const endDate = event.end.toDate();

    startDate.setHours(start.getHours());
    startDate.setMinutes(start.getMinutes());
    endDate.setHours(end.getHours());
    endDate.setMinutes(end.getMinutes());

    newEvent.startMillis = startDate.getTime();
    newEvent.endMillis = endDate.getTime();
    newEvent.id = event.id;

    return newEvent;
  }

  private updateSerialEvents(
    events: UpdateEventDTO[],
    eventRoute: string
  ): void {
    this.createEventLoading.next(true);
    this.functions
      .httpsCallable('updateSerialEvents')(events)
      .subscribe(
        () => {
          this.fetchEvents();

          this.toastService.emitToast({
            type: TOAST_TYPE.SUCCESS,
            text: 'successfully updated event',
          });

          this.createEventLoading.next(null);
          this.router.navigate([
            this.store.locale,
            SA_NAVIGATION_PATH.EVENTS,
            eventRoute,
          ]);
        },
        error => {
          console.error(error);
          this.createEventLoading.next(null);
          this.toastService.emitToast({
            type: TOAST_TYPE.ERROR,
            text: 'taost_something_went_wrong',
          });
        }
      );
  }

  private getSerialEventData(events: SaEvent[]): SaEvent[] {
    return events
      .filter(e => e && e.id !== e.serialEventId) // hide parent serial Events
      .map(event => {
        if (
          event.serialEventId &&
          event.id !== event.serialEventId &&
          !event.manipulated
        ) {
          const parentEvent = this.store.getSerialParentEvent(
            event.serialEventId
          );
          if (parentEvent) {
            return this.getSerialEvent(event, parentEvent);
          }
        }

        return event;
      });
  }

  private getSerialEvent(event: SaEvent, parentEvent: SaEvent): SaEvent {
    if (!parentEvent) {
      return event;
    }

    event.groupId = parentEvent.groupId || '';
    event.title = parentEvent.title;
    event.description = parentEvent.description;
    event.directions = parentEvent.directions;
    event.imageUrl = parentEvent.imageUrl;
    event.location = parentEvent.location;
    event.type = parentEvent.type;
    event.price = parentEvent.price;
    event.gallery = parentEvent.gallery;
    event.isMembersOnly = parentEvent.isMembersOnly;
    event.groupSizeMin = parentEvent.groupSizeMin;
    event.groupSize = parentEvent.groupSize;
    return event;
  }
}
