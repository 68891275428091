import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { NgModule, Injectable } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import * as Hammer from 'hammerjs';
import {
  HammerGestureConfig,
  HAMMER_GESTURE_CONFIG,
} from '@angular/platform-browser';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import {
  AngularFireFunctionsModule,
  ORIGIN,
  REGION,
} from '@angular/fire/functions';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { InlineSVGModule } from 'ng-inline-svg';
import { Router, Scroll } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { filter, map } from 'rxjs/operators';

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = {
    swipe: { direction: Hammer.DIRECTION_ALL },
  } as any;
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    HammerModule,
    InlineSVGModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFirestoreModule,
    AngularFireFunctionsModule,
    AngularFireStorageModule,
  ],
  providers: [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
    { provide: REGION, useValue: 'europe-west3' },
    // { provide: ORIGIN, useValue: 'http://localhost:5001' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(router: Router, viewportScroller: ViewportScroller) {
    AppModule.scrollToTopOnForwardNavigation(router, viewportScroller);
  }
  static scrollToTopOnForwardNavigation(
    router: Router,
    viewportScroller: ViewportScroller
  ): void {
    // Only scroll events, which emit when the navigation
    const scrollEvents$ = router.events.pipe(
      filter(event => event instanceof Scroll),
      map(event => event as Scroll)
    );
    scrollEvents$.subscribe(scrollEvent => {
      if (scrollEvent.position) {
        // backward navigation
        viewportScroller.scrollToPosition(scrollEvent.position);
      } else if (scrollEvent.anchor) {
        // anchor navigation
        viewportScroller.scrollToAnchor(scrollEvent.anchor);
      } else {
        // forward navigation
        viewportScroller.scrollToPosition([0, 0]);
      }
    });
  }
}
