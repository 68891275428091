import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Router } from '@angular/router';
import { firestore } from 'firebase';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  CreateForumTopicDTO,
  CreateIntervisionEventDTO,
  DeleteForumTopicDTO,
  SaEvent,
  SaEventParticipant,
  SaForumTopic,
  SaUser,
  SA_COLLECTIONS,
  SA_EVENT_PARTICIPANT_ROLE,
  SA_EVENT_PARTICIPANT_STATUS,
  SA_EVENT_STATUS,
  SA_NAVIGATION_PATH,
  SA_QUERY_PARAMS,
  TOAST_TYPE,
  UpdateForumTopicDTO,
  UpdateIntervisionEventDTO,
} from 'shared/interfaces';
import { ToastService } from 'shared/modules/toast-controller/services/toast.service';
import { EventService } from '../event/event.service';
import { StoreService } from '../store/store.service';

@Injectable({
  providedIn: 'root',
})
export class IntervisionEventService {
  private createEventLoading = new BehaviorSubject<boolean>(null);
  private createForumTopicLoading = new BehaviorSubject<boolean>(null);

  constructor(
    private eventService: EventService,
    private toastService: ToastService,
    private functions: AngularFireFunctions,
    private db: AngularFirestore,
    private router: Router,
    private store: StoreService
  ) {}

  public createEventLoading$() {
    return this.createEventLoading;
  }

  public createForumTopicLoading$() {
    return this.createForumTopicLoading;
  }

  public async getEventParticipantIDs(
    eventId: string,
    role: SA_EVENT_PARTICIPANT_ROLE
  ) {
    return await this.eventService.getEventParticipantIDs(
      eventId,
      role,
      SA_COLLECTIONS.INTERVISION_EVENTS
    );
  }

  public fetchMyIntervisionEvents() {
    return this.eventService.fetchMyEvents(SA_COLLECTIONS.INTERVISION_EVENTS);
  }

  public fetchUpcomingIntervisionEvents(locale: string): Promise<SaEvent[]> {
    const now = firestore.Timestamp.fromDate(new Date());

    return this.db
      .collection(SA_COLLECTIONS.INTERVISION_EVENTS)
      .ref.where(SA_QUERY_PARAMS.START, '>', now)
      .where(SA_QUERY_PARAMS.STATUS, '==', SA_EVENT_STATUS.ACTIVE)
      .where(SA_QUERY_PARAMS.LOCALE, '==', locale)
      .orderBy(SA_QUERY_PARAMS.START, 'asc')
      .get()
      .then(querySnapshot => {
        const events: SaEvent[] = [];
        querySnapshot.forEach(doc => {
          events.push(doc.data() as SaEvent);
        });
        this.store.setUpcomingIntervisionEvents(events);
        return events;
      })
      .catch(error => {
        console.error('Error getting documents: ', error);
        return [];
      });
  }

  public fetchForumTopics(locale: string): Promise<SaForumTopic[]> {
    return this.db
      .collection(SA_COLLECTIONS.FORUM_TOPICS)
      .ref.where(SA_QUERY_PARAMS.LOCALE, '==', locale)
      .get()
      .then(querySnapshot => {
        const forumTopics: SaForumTopic[] = [];
        querySnapshot.forEach(doc => {
          forumTopics.push(doc.data() as SaForumTopic);
        });
        this.store.setForumTopics(forumTopics);
        return forumTopics;
      })
      .catch(error => {
        console.error('Error getting documents: ', error);
        return [];
      });
  }

  public async getEventIDsByZip(zip: string) {
    const eventIDs = await this.eventService.getEventIDsByZip(
      zip,
      SA_COLLECTIONS.INTERVISION_EVENTS
    );
    return eventIDs;
  }

  public fetchEvent(id: string): Observable<SaEvent | undefined> {
    return this.eventService.fetchEvent(id, SA_COLLECTIONS.INTERVISION_EVENTS);
  }

  public fetchForumTopic(id: string): Observable<SaForumTopic | undefined> {
    return this.db
      .collection(SA_COLLECTIONS.FORUM_TOPICS)
      .doc<SaForumTopic>(id)
      .valueChanges();
  }

  public async getEventIDsByLocation(location: string) {
    const eventIDs = await this.eventService.getEventIDsByLocation(
      location,
      SA_COLLECTIONS.INTERVISION_EVENTS
    );
    return eventIDs;
  }

  public async getEventIDsByTitle(search: string) {
    const eventIDs = await this.eventService.getEventIDsByTitle(
      search,
      SA_COLLECTIONS.INTERVISION_EVENTS
    );
    return eventIDs;
  }

  public joinIntervisionEvent(
    event: SaEvent,
    user: SaUser
  ): Observable<SaEventParticipant | undefined> {
    const initialStatus = SA_EVENT_PARTICIPANT_STATUS.ACCEPTED;
    return this.functions.httpsCallable('addIntervisionEventParticipant')({
      eventId: event.id,
      userId: user.id,
      status: initialStatus,
      role: SA_EVENT_PARTICIPANT_ROLE.PARTICIPANT,
      isIntervision: true,
    });
  }

  public unsubscribeFromIntervisionEvent(
    event: SaEvent,
    user: SaUser
  ): Observable<SaEventParticipant | undefined> {
    return this.functions.httpsCallable('removeIntervisionEventParticipant')({
      eventId: event.id,
      userId: user.id,
      isIntervision: true,
    });
  }

  public removeIntervisionEventParticipant(eventId: string, userId: string) {
    return this.functions.httpsCallable('removeIntervisionEventParticipant')({
      eventId,
      userId,
      isIntervision: true,
    });
  }

  public createForumTopic(createForumTopicDTO: CreateForumTopicDTO) {
    this.createForumTopicLoading.next(true);
    this.functions
      .httpsCallable('createForumTopic')(createForumTopicDTO)
      .subscribe(
        async (topic: SaForumTopic) => {
          this.toastService.emitToast({
            type: TOAST_TYPE.SUCCESS,
            text: 'successfully created topic',
          });
          this.createForumTopicLoading.next(null);
          this.router.navigate([
            this.store.locale,
            SA_NAVIGATION_PATH.INTERVISION,
            SA_NAVIGATION_PATH.FORUM,
            topic.id,
          ]);
        },
        error => {
          console.error(error);
          this.toastService.emitToast({
            type: TOAST_TYPE.ERROR,
            text: 'taost_something_went_wrong',
          });
        }
      );
  }

  public createIntervisionEvent(
    createIntervisionEventDTO: CreateIntervisionEventDTO
  ) {
    this.createEventLoading.next(true);
    this.functions
      .httpsCallable('createIntervisionEvent')(createIntervisionEventDTO)
      .subscribe(
        async (event: SaEvent) => {
          this.toastService.emitToast({
            type: TOAST_TYPE.SUCCESS,
            text: 'successfully created event',
          });
          this.createEventLoading.next(null);
          this.router.navigate([
            this.store.locale,
            SA_NAVIGATION_PATH.INTERVISION,
            event.id,
          ]);
        },
        error => {
          console.error(error);
          this.toastService.emitToast({
            type: TOAST_TYPE.ERROR,
            text: 'taost_something_went_wrong',
          });
        }
      );
  }

  public updateEvent(updateEventDTO: UpdateIntervisionEventDTO) {
    this.createEventLoading.next(true);
    this.functions
      .httpsCallable('updateIntervisionEvent')(updateEventDTO)
      .subscribe(
        (event: SaEvent) => {
          this.toastService.emitToast({
            type: TOAST_TYPE.SUCCESS,
            text: 'successfully updated event',
          });

          this.createEventLoading.next(null);
          this.router.navigate([
            this.store.locale,
            SA_NAVIGATION_PATH.INTERVISION,
            event.id,
          ]);
        },
        error => {
          console.error(error);
          this.toastService.emitToast({
            type: TOAST_TYPE.ERROR,
            text: 'taost_something_went_wrong',
          });
        }
      );
  }

  public updateForumTopic(updateForumTopicDTO: UpdateForumTopicDTO) {
    this.createForumTopicLoading.next(true);
    this.functions
      .httpsCallable('updateForumTopic')(updateForumTopicDTO)
      .subscribe(
        (topic: SaForumTopic) => {
          this.toastService.emitToast({
            type: TOAST_TYPE.SUCCESS,
            text: 'successfully updated topic',
          });

          this.createForumTopicLoading.next(null);
          this.router.navigate([
            this.store.locale,
            SA_NAVIGATION_PATH.INTERVISION,
            SA_NAVIGATION_PATH.FORUM,
            topic.id,
          ]);
        },
        error => {
          console.error(error);
          this.toastService.emitToast({
            type: TOAST_TYPE.ERROR,
            text: 'taost_something_went_wrong',
          });
        }
      );
  }

  public cancelEvent(eventId: string) {
    return this.functions.httpsCallable('cancelIntervisionEvent')({ eventId });
  }

  public deleteForumTopic(topicId: string) {
    const dto: DeleteForumTopicDTO = {
      id: topicId,
    };
    return this.functions.httpsCallable('deleteForumTopic')(dto);
  }
}
