import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SaToast } from 'shared/interfaces';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private toastSubject = new Subject<SaToast>();
  constructor() {}

  public toast$() {
    return this.toastSubject.asObservable();
  }

  public emitToast(toast: SaToast) {
    this.toastSubject.next({
      type: toast.type,
      text: toast.text,
      timestamp: Date.now(),
    });
  }
}
