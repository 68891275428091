import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'projects/salsa-fun/src/environments/environment';
import { StoreService } from '../../services/store/store.service';
import { SA_NAVIGATION_PATH } from 'shared/interfaces';

@Injectable({
  providedIn: 'root',
})
export class LocaleGuard implements CanActivate {
  constructor(private router: Router, private store: StoreService) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (!next.params.locale) {
      if (this.store.locale) {
        this.router.navigateByUrl(`/${this.store.locale}`);
        return false;
      } else {
        if (environment.defaultLocale) {
          this.router.navigateByUrl(`/${environment.defaultLocale}`);
          return false;
        } else {
          this.router.navigateByUrl(`/${SA_NAVIGATION_PATH.CHOOSE_TENANT}`);
          return false;
        }
      }
    }
    if (environment.locales.indexOf(next.params.locale) === -1) {
      if (this.store.locale) {
        this.router.navigateByUrl(`/${this.store.locale}${state.url}`);
        return false;
      } else {
        if (environment.defaultLocale) {
          this.router.navigateByUrl(
            `/${environment.defaultLocale}${state.url}`
          );
          return false;
        } else {
          this.router.navigateByUrl(`/${SA_NAVIGATION_PATH.CHOOSE_TENANT}`);
          return false;
        }
      }
    }
    return true;
  }
}
