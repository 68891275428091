import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SA_NAVIGATION_PATH } from 'shared/interfaces';
import { LocaleGuard } from './guards/locale/locale.guard';

const routes: Routes = [
  {
    path: 'designsystem',
    loadChildren: () =>
      import(
        '../../../../shared/modules/designsystem/designsystem.module'
      ).then(mod => mod.DesignsystemModule),
  },
  {
    path: SA_NAVIGATION_PATH.CHOOSE_TENANT,
    loadChildren: () =>
      import('./modules/choose-tenant/choose-tenant.module').then(
        mod => mod.ChooseTenantModule
      ),
  },
  {
    path: ':locale/comments',
    loadChildren: () =>
      import('./modules/comments/comments.module').then(
        mod => mod.CommentsModule
      ),
  },
  {
    path: ':locale',
    canActivate: [LocaleGuard],
    loadChildren: () =>
      import('./modules/salsa-fun/salsa-fun.module').then(
        mod => mod.SalsaFunModule
      ),
  },
  {
    path: '',
    redirectTo: SA_NAVIGATION_PATH.CHOOSE_TENANT,
    pathMatch: 'full',
  },
  { path: '**', redirectTo: 'en/groups', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
