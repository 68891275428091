import { NavigationExtras } from '@angular/router';

export enum SA_NAVIGATION_PATH {
  EVENTS = 'events',
  GROUPS = 'groups',
  INTERVISION = 'intervision',
  USER = 'user',
  LOGIN = 'login',
  LOGOUT = 'logout',
  FORGOT_PASSWORD = 'forgot-password',
  REGISTER = 'register',
  TRAININGS = 'trainings',
  TRAINING_ID = 'trainingId',
  DOWNLOADS = 'downloads',
  FAQ = 'faq',
  DASHBOARD = 'dashboard',
  EVENT_ID = 'eventId',
  GROUP_ID = 'groupId',
  USER_ID = 'userId',
  EDIT = 'edit',
  EXERCISES = 'exercises',
  EXERCISE_ID = 'exerciseId',
  SEARCH = 'search',
  TRAINING_TEMPLATES = 'training-templates',
  MANAGE = 'manage',
  GROUPS_MEMBERS = 'group-members',
  EVENTS_MEMBERS = 'event-members',
  UPDATE = 'update',
  ADMIN = 'admin',
  ADD_TRAINER = 'assign-trainer',
  CONTACT_ME = 'contact-me',
  GDPR = 'gdpr',
  DATA_PRIVACY = 'data-privacy',
  CHOOSE_TENANT = 'choose-tenant',
  RESET_PASSWORD = 'reset-password',
  HYVOR_ID = 'hyvorId',
  FORUM = 'forum',
  TOPIC_ID = 'topicId',
}

export interface SaNavigationItem {
  commands: any[];
  extras?: NavigationExtras;
}
