export interface SaEnvironment {
  production: boolean;
  locales: SA_LOCALES[];
  defaultLocale: SA_LOCALES;
  showNewsletterCheckboxLocales: SA_LOCALES[];
  admins: SaEnvironmentAdmins;
  hyvorURL: string;
  firebase: {
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
  };
}

export enum SA_LOCALES {
  EN = 'en',
  NL = 'nl',
  DE_AT = 'de-at',
  DE_CH = 'de-ch',
}

export const HYVOR_LANG_KEY = {
  en: 'en',
  nl: 'nl',
  'de-at': 'de',
  'de-ch': 'de',
};

export const FIREBASE_LANG_KEY = {
  en: 'en',
  nl: 'nl',
  'de-at': 'de',
  'de-ch': 'de',
};

export const DATEPIPE_LOCALE = {
  en: 'en',
  nl: 'nl',
  'de-at': 'de',
  'de-ch': 'de',
};

export enum SA_HOSTS {
  DE_CH = 'community.terzstiftung.ch',
}

export interface SaEnvironmentAdmins {
  en: string;
  nl: string;
  'de-at': string;
  'de-ch': string;
}
