import { SaNavigationItem } from './navigation.interface';

export enum MODAL_TYPE {
  SUCCESS = 'success',
  INFO = 'info',
  ERROR = 'error',
}

export enum MODAL_ACTION {
  OPEN = 'open',
  CLOSE = 'close',
  CANCEL = 'cancel',
  CONFIRM = 'confirm',
  DENY = 'deny',
}

export interface MekPromptResult {
  text: string;
}

export interface SaModal {
  type: MODAL_TYPE;
  text: string;
  headline?: string;
  action?: SaModalAction;
  actions?: SaModalAction[];
}

export interface SaModalAction {
  actionLabel: string;
  type: MODAL_ACTION_TYPE;
  navigationItem?: SaNavigationItem;
}

export enum MODAL_ACTION_TYPE {
  NAVIGATE = 'navigate',
  CONFIRM = 'confirm',
  PROMPT = 'prompt',
  DENY = 'deny',
}
