import { Component, HostListener, NgZone, OnInit } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import {
  ActionPerformed,
  PushNotifications,
  PushNotificationSchema,
  Token,
} from '@capacitor/push-notifications';
import { IconService } from 'shared/services/icon/icon.service';
import { ApplicationService } from './services/application/application.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public showOutline = false;

  @HostListener('document:keydown', ['$event']) handleKeyboardEvent(
    event: KeyboardEvent
  ) {
    this.zone.runOutsideAngular(() => {
      if (event.key === 'Tab') {
        if (!this.showOutline) {
          this.showOutline = true;
        }
      }
    });
  }

  @HostListener('document:click', ['$event']) handleClickEvent(
    event: KeyboardEvent
  ) {
    this.zone.runOutsideAngular(() => {
      if (this.showOutline && event.type === 'click') {
        this.showOutline = false;
      }
    });
  }

  constructor(
    private applicationService: ApplicationService,
    private iconService: IconService,
    private zone: NgZone
  ) {}

  ngOnInit(): void {
    this.init();
  }

  private init() {
    this.applicationService.init();

    this.iconService.initRegistry();

    const isPushNotificationsAvailable =
      Capacitor.isPluginAvailable('PushNotifications');

    if (isPushNotificationsAvailable) {
      // this.initPushNotifications();
    }
  }

  private initPushNotifications(): void {
    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        PushNotifications.register();
      } else {
        // Show some error
      }
    });

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration', (token: Token) => {
      this.applicationService.setNotificationToken(token.value);
    });

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError', (error: any) => {});

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotificationSchema) => {}
    );

    // Method called when tapping on a notification
    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: ActionPerformed) => {}
    );
  }
}
