export interface SaDownload {
  _uid: string;
  asset: SaDownloadAsset;
  title: string;
  size: string;
  type: SA_DOWNLOAD_TYPE;
}

export interface SaDownloadAsset {
  filename: string;
}

export enum SA_DOWNLOAD_TYPE {
  PDF = 'pdf',
  IMAGE = 'image',
}
