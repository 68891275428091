export interface SaImageGallery {
  images: SaImage[];
}

export interface SaImage {
  src: string;
  title: string;
}

export interface SaLightbox {
  image: SaImage;
  next?: number;
  prev?: number;
}

export class AddGalleryImageDTO {
  entityId: string;
  image: SaImage;
}

export class UpdateGalleryDTO {
  entityId: string;
  gallery: SaImageGallery;
}
