import { SaLocation, SaPrice } from '../interfaces';
import { SaUser } from './user.interface';

export enum GROUP_TYPE {
  PUPLIC = 'public',
  PRIVATE = 'private',
}

export interface PageHeaderInfo {
  id: string;
  headline: string;
  ownerId: string;
  date?: string;
  startTime?: string;
  endTime?: string;
  location: SaLocation;
  price?: SaPrice;
  participantCount?: number;
  membersCount?: number;
  isMemberOnly?: boolean;
  groupType?: GROUP_TYPE;
  host?: string;
  hostId?: string;
  group?: string;
  groupId?: string;
  icon: string;
  trainer?: SaUser;
  coTrainers?: SaUser[];
  groupSize?: number;
  isMembersOnly?: boolean;
}
