import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

@Injectable({
  providedIn: 'root',
})
export class IconService {
  private icons: string[] = [
    'arrow-thick-up',
    'alarm',
    'baseball',
    'basketball',
    'bubble-question',
    'bubbles',
    'calendar-empty',
    'check',
    'check-green',
    'checkmark-circle',
    'chevron-down',
    'chevron-right-circle',
    'chevron-right',
    'circle-minus',
    'clock3',
    'cross',
    'dots',
    'download',
    'earth',
    'enter',
    'envelope-open',
    'exclamation-outline',
    'exit',
    'folder-download',
    'heart',
    'home2',
    'information-outline',
    'map-marker',
    'money',
    'network',
    'pencil3',
    'plus-circle',
    'plus',
    'quote',
    'refresh',
    'reload',
    'soccer',
    'star',
    'star-filled',
    'sync',
    'tag',
    'telephone',
    'thumbs-up',
    'trash2',
    'trophy',
    'users2',
    'walk',
  ];

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {}

  public initRegistry(): void {
    this.icons.map((iconName: string) => {
      this.matIconRegistry.addSvgIcon(
        iconName,
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `assets/icons/${iconName}.svg`
        )
      );
    });
  }

  public getIcons(): string[] {
    return this.icons;
  }
}
