import { IsEmail, IsNotEmpty } from 'class-validator';
import { firestore } from 'firebase';
import { SA_LOCALES } from './environment.interface';
import { SA_USER_NOTIFICATION_TYPE } from './notification.interface';

export interface SaUser {
  id: string;
  created: firestore.Timestamp;
  email: string;
  firstName: string;
  lastName: string;
  description?: string;
  preferredLocation?: SaLocation;
  phone?: string;
  website?: string;
  imageUrl?: string;
  roles?: Array<SA_USER_ROLES>;
  about?: string;
  newsletterAccepted?: boolean;
  registeredLocale?: SA_LOCALES;
  deleted?: firestore.Timestamp;
  privateUser?: boolean;
  notificationToken?: string;
}

export enum SA_USER_ROLES {
  USER = 'user',
  TRAINER = 'trainer',
  ADMIN = 'admin',
}

export enum SA_USER_FEATURE {
  CREATE_GROUP = 'CREATE_GROUP',
  CREATE_EVENT = 'CREATE_EVENT',
  CANCEL_EVENT = 'CANCEL_EVENT',
  NAV_TRAININGS = 'NAV_TRAININGS',
  NAV_DOWNLOADS = 'NAV_DOWNLOADS',
  ADD_TRAINER = 'ADD_TRAINER',
  ADD_FORUM_TOPIC = 'ADD_FORUM_TOPIC',
}

export interface SaUserFeature {
  id: string;
  value: any;
  key: SA_USER_FEATURE;
}

export interface SaUserNotificationSetting {
  id: string;
  value: any;
  key: SA_USER_NOTIFICATION_TYPE;
  roles: SA_USER_ROLES[];
}

export class UpdateUserNotificationSettingsDTO {
  @IsNotEmpty()
  settings: SaNotificationSetting[];

  @IsNotEmpty()
  userId: string;
}

export interface SaNotificationSetting {
  key: SA_USER_NOTIFICATION_TYPE;
  model: boolean;
  roles: SA_USER_ROLES[];
}

export interface SaLocation {
  address?: string;
  zip: string;
  city: string;
  // lat: number;
  // long: number;
}

export class CreateUserDTO {
  @IsEmail()
  email: string;

  @IsNotEmpty()
  password: string;

  @IsNotEmpty()
  firstName: string;

  @IsNotEmpty()
  lastName: string;

  preferredLocation?: SaLocation;
  newsletterAccepted?: boolean;
  registeredLocale?: SA_LOCALES;
  notificationToken?: string;
}

export class UpdateUserDTO {
  id: string;
  email?: string;
  password?: string;
  firstName?: string;
  lastName?: string;
  preferredLocation?: string;
  description?: string;
  imageUrl?: string;
  phone?: string;
  website?: string;
  privateUser?: boolean;
  notificationToken?: string;
}

export class UpdateUserNotificationDTO {
  @IsNotEmpty()
  id: string;

  @IsNotEmpty()
  notificationToken: string;
}

export class AddTrainerFeaturesDTO {
  id: string;
  email: string;
}

export class ResetPasswordDTO {
  id: string;
  email: string;
  lang: string;
  locale: string;
}
