import { IsNumber, IsString } from 'class-validator';
import { firestore } from 'firebase';
import { SaImageGallery } from './gallery.interface';
import { SaLocation } from './user.interface';

export interface SalEventCardItem {
  id: string;
  groupId: string;
  ownerId: string;
  title: string;
  imageUrl: string;
  date: string;
  start: string;
  end: string;
  location: string;
  price: number;
  iconType: string;
  participants: number;
  isGroupMember: boolean;
  isMembersOnly: boolean;
  groupSize?: number;
}

export enum USER_ACTION {
  JOIN = 'join',
  UNSUBSCRIBE = 'unsubscribe',
}

export interface SaEvent {
  id: string;
  created: firestore.Timestamp;
  ownerId: string;
  groupId: string;
  title: string;
  description?: string;
  directions?: string;
  imageUrl?: string;
  location: SaLocation;
  type: string;
  start: firestore.Timestamp;
  end: firestore.Timestamp;
  status: SA_EVENT_STATUS;
  price?: number;
  locale: string;
  isMembersOnly?: boolean;
  groupSizeMin?: number;
  groupSize?: number;
  participantCount?: number;
  gallery?: SaImageGallery;
  serialEventId?: string;
  manipulated?: boolean;
}

export enum SA_EVENT_REPETITION_TYPE {
  SINGLE = 'single',
  REPEATING = 'repeating',
}

export enum SA_EVENT_STATUS {
  ACTIVE = 'active',
  CANCELLED = 'cancelled',
}

export class SaPrice {
  value: number;
  currency: SA_PRICE_CURRENCY;
}

export enum SA_PRICE_CURRENCY {
  EUR = 'EUR',
  USD = 'USD',
  CHF = 'CHF',
}

export enum SA_EVENT_FREQUENCY {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
}

export const SaEventFrequency = {
  [SA_EVENT_FREQUENCY.DAILY]: 'event_form_repetition_frequency_daily',
  [SA_EVENT_FREQUENCY.WEEKLY]: 'event_form_repetition_frequency_weekly',
  [SA_EVENT_FREQUENCY.MONTHLY]: 'event_form_repetition_frequency_monthly',
};

export class CreateEventDTO {
  @IsString() groupId: string;
  @IsString() ownerId: string;
  @IsString() title: string;
  @IsString() description?: string;
  @IsString() imageUrl?: string;
  @IsNumber() startMillis: number;
  @IsNumber() endMillis: number;
  locale: string;
  location: SaLocation;
  directions?: string;
  price: number;
  type: string;
  @IsString() status: SA_EVENT_STATUS;
  isMembersOnly?: boolean;
  groupSizeMin?: number;
  groupSize?: number;
  gallery?: SaImageGallery;
}

export class CreateSerialEventDTO extends CreateEventDTO {
  @IsString() frequency: SA_EVENT_FREQUENCY;
  @IsNumber() repetition: number;
}

export class CreateIntervisionEventDTO {
  @IsString() ownerId: string;
  @IsString() title: string;
  @IsString() description?: string;
  @IsString() imageUrl?: string;
  @IsNumber() startMillis: number;
  @IsNumber() endMillis: number;
  locale: string;
  location: SaLocation;
  directions?: string;
  price: number;
  @IsString() status: SA_EVENT_STATUS;
}

export class UpdateEventDTO {
  id: string;
  title?: string;
  description?: string;
  imageUrl?: string;
  startMillis?: number;
  endMillis?: number;
  location?: SaLocation;
  directions?: string;
  price?: number;
  type?: string;
  groupSizeMin?: number;
  groupSize?: number;
  gallery?: SaImageGallery;
  isMembersOnly?: boolean;
  manipulated?: boolean;
}

export class UpdateIntervisionEventDTO {
  id: string;
  title?: string;
  description?: string;
  imageUrl?: string;
  startMillis?: number;
  endMillis?: number;
  location?: SaLocation;
  directions?: string;
  price?: number;
}
