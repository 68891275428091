import { Injectable } from '@angular/core';
import { EventService } from '../event/event.service';
import { ExerciseService } from '../exercise/exercise.service';
import { GroupService } from '../group/group.service';
import { IntervisionEventService } from '../intervision/intervision-event.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class ApplicationService {
  constructor(
    private userService: UserService,
    private groupService: GroupService,
    private eventService: EventService,
    private intervisionEventService: IntervisionEventService,
    private exerciseService: ExerciseService
  ) {}

  public init() {
    this.userService.fetchUser();
    this.userService.fetchUserFeatures();
    this.userService.fetchUserNotificationSettings();
    this.groupService.fetchMyGroupMembers();
    this.groupService.fetchMyPendingGroupMemberships();
    this.groupService.fetchMyGroups();
    this.eventService.fetchMyEventParticipants();
    this.eventService.fetchMyPendingEventMemberships();
    this.eventService.fetchMyEvents();
    this.intervisionEventService.fetchMyIntervisionEvents();
    this.exerciseService.fetchMyUserFavorites();
  }

  public setNotificationToken(notificationToken: string): void {
    this.userService.notificationToken = notificationToken;
  }
}
