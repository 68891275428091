export interface SalCardItem {
  id: string;
  title: string;
  imageUrl: string;
  location: string;
  iconType: string;

  groupId?: string;
  ownerId?: string;
  date?: string;
  start?: string;
  end?: string;
  price?: string;
  description?: string;
  trainers?: any[];
  members?: number;
}

export enum SAL_CARD_TYPE {
  EVENT = 'event',
  GROUP = 'group',
}
