import { GroupMemberDTO } from './group-member.interface';
import { SaLocation } from './user.interface';
import { firestore } from 'firebase';
import { SaImageGallery } from './gallery.interface';

export interface SalGroupCardItem {
  id: string;
  title: string;
  description: string;
  imageUrl: string;
  location: string;
  trainers: any[];
  members: number;
  iconType: string;
  created: string;
  joined?: string;
  groupSize?: number;
}

export interface SaGroup {
  id: string;
  created: firestore.Timestamp;
  ownerId: string;
  title: string;
  description?: string;
  imageUrl?: string;
  location: SaLocation;
  type: string;
  isPublic: boolean;
  allowComments: boolean;
  memberCount?: number;
  status: SA_GROUP_STATUS;
  locale: string;
  groupSize?: number;
  gallery?: SaImageGallery;
}

export enum SA_GROUP_STATUS {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export class CreateGroupDTO {
  title: string;
  description?: string;
  imageUrl?: string;
  location: SaLocation;
  type: string;
  isPublic: boolean;
  allowComments: boolean;
  trainers: GroupMemberDTO[];
  locale: string;
  status: SA_GROUP_STATUS;
  groupSize?: number;
  gallery?: SaImageGallery;
}

export class DeleteGroupDTO {
  groupId: string;
  ownerId: string;
}

export class UpdateGroupDTO {
  id: string;
  title?: string;
  description?: string;
  imageUrl?: string;
  location?: SaLocation;
  type?: string;
  groupSize?: number;
  gallery?: SaImageGallery;
  isPublic: boolean;
}
