import { IsString } from 'class-validator';

export interface SaGroupMember {
  id: string;
  userId: string;
  groupId: string;
  timestamp: any;
  status: SA_GROUP_MEMBER_STATUS;
  role: SA_GROUP_MEMBER_ROLE;
}

export enum SA_GROUP_MEMBER {
  USER_ID = 'userId',
  GROUP_ID = 'groupId',
  TIMESTAMP = 'timestamp',
  STATUS = 'status',
  ROLE = 'role',
}

export enum SA_GROUP_MEMBER_STATUS {
  WAITING_FOR_ACCEPT = 'waiting_for_accept',
  ACCEPTED = 'accepted',
  // INVITED = 'invited',
  // PENDING = 'pending',
  // BLOCKED = 'blocked',
  // DECLINED = 'declined'
}

export enum SA_GROUP_MEMBER_ROLE {
  MEMBER = 'member',
  TRAINER = 'trainer',
  CO_TRAINER = 'co_trainer',
}

export class GroupMemberDTO {
  groupId?: string;

  @IsString()
  userId: string;

  @IsString()
  status: SA_GROUP_MEMBER_STATUS;

  @IsString()
  role: SA_GROUP_MEMBER_ROLE;
}

export class DeleteGroupMemberDTO {
  groupId?: string;

  @IsString()
  userId: string;

  status?: SA_GROUP_MEMBER_STATUS;

  role?: SA_GROUP_MEMBER_ROLE;
}

export class AddGroupMemberDTO {
  groupId: string;
  userId: string;
  status: SA_GROUP_MEMBER_STATUS;
  role: SA_GROUP_MEMBER_ROLE;
}

export class UpdateGroupMemberStatusDTO {
  groupId: string;
  userId: string;
  status: SA_GROUP_MEMBER_STATUS;
}
